.product-btn2 {
    background: #f0f0f0;
    padding: 0px 0px 10px;
    a {
        text-align: center;
        color: #fff;
        float: left;
        width: 50%;
        height: 46px;
        line-height: 46px;
    }
    .red {
        background: $red;
    }
    .gray {
        background: #555;
    }
}
.product-banner {
    padding-bottom: (546/750)*100%;
    position: relative;
    width: 100%;
    .banner-box {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        a {
            position: relative;
            width: 100%;
            height: 100%;
            display: block;
            overflow: hidden;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                max-height: 100%;
            }
        }
    }
    .prev {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: px2rem(20px);
        z-index: 10;
        width: 30px;
        height: 30px;
        background: url(../images/product_arrow_2.png);
        background-size: 100% 100%;
    }
    .next {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%) rotate(180deg);
        right: px2rem(20px);
        z-index: 10;
        width: 30px;
        height: 30px;
        background: url(../images/product_arrow_2.png);
        background-size: 100% 100%;
    }
    .product-pagination {
        position: absolute;
        top: auto;
        bottom: px2rem(40px);
        padding: 0 px2rem(20px);
        box-sizing: border-box;
        left: 0px;
        text-align: right;
        width: 100%;
        z-index: 10;
        span {
            margin: 0 3px !important;
            opacity: 1;
            background: #fff;
            width: 8px;
            height: 8px;
            &.swiper-pagination-bullet-active {
                background: $red;
            }
        }
    }
}
.product-info-article {
    background: #fff;
    padding: px2rem(40px) px2rem(50px) px2rem(20px);
    margin-bottom: px2rem(20px);
    .title {
        font-size: px2rem(34px);
        font-weight: 800;
        color: #333;
        margin-bottom: px2rem(20px);
    }
    .li {
        .li-title {
            position: relative;
            font-size: px2rem(28px);
            color: #333;
            line-height: px2rem(70px);
            border-bottom: 1px solid #ccc;
            .t {
                display: inline-block;
                vertical-align: top;
                margin-right: px2rem(50px);
                font-size: px2rem(28px);
                color: #333;
                line-height: px2rem(70px);
                position: relative;
                &.on {
                    &:before {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: px2rem(4px);
                        width: 100%;
                        content: '';
                        background: $red;
                    }
                }
            }
        }
        .li-article {
            display: none;
            padding: px2rem(30px) 0;
            font-size: px2rem(28px);
            line-height: px2rem(40px);
            color: #777;
            &:first-child {
                display: block;
            }
        }
    }
}
.product-info-list {
    background: #eeeff0;
    padding: px2rem(40px) px2rem(10px) px2rem(50px);
    .title {
        padding: 0 px2rem(10px);
        font-size: 16px;
        margin-bottom: px2rem(30px);
        color: #333;
        position: relative;
        text-align: center;
        &:before {
            position: absolute;
            width: 100%;
            border-top: 1px solid #d6d7d8;
            display: block;
            height: 0;
            content: '';
            left: 0;
            top: 50%;
            z-index: 1;
        }
        .t {
            position: relative;
            font-size: 16px;
            color: #333;
            display: inline-block;
            vertical-align: top;
            background-color: #eeeff0;
            z-index: 2;
            padding: 0 px2rem(20px);
        }
    }
    &-box {
        font-size: 0px;
        .li {
            display: inline-block;
            vertical-align: top;
            margin-bottom: px2rem(20px);
            padding: 0 px2rem(10px);
            width: 50%;
            box-sizing: border-box;
            a {
                padding: px2rem(20px);
                padding-bottom: 0px;
                display: block;
                background: #fff;
                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (189/318)*100%;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .text {
                    text-align: center;
                    font-size: 14px;
                    line-height: 30px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .product-bottom {
        white-space: nowrap;
        overflow-x: auto;
        text-align: center;
        padding-top: px2rem(30px);
        .li {
            padding: 0 px2rem(20px);
            display: inline-block;
            vertical-align: top;
            color: #777;
            img {
                width: 40px;
            }
        }
    }
    .service-form {
        padding-top: px2rem(30px);
    }
}
.product-series-list {
    padding: px2rem(50px) px2rem(20px) px2rem(20px);
    background-color: #f0f0f0;
    .swiper-slide {
        font-size: 0;
        .item {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
            margin-bottom: px2rem(20px);
            &:nth-child(2n+1) {
                padding-right: px2rem(8px);
            }
            &:nth-child(2n) {
                padding-left: px2rem(8px);
            }
            a {
                display: block;
            }
            .img {
                width: 100%;
                padding-top: (232/350) * 100%;
                position: relative;
                overflow: hidden;
                border-top-right-radius: px2rem(10px);
                border-top-left-radius: px2rem(10px);
                img {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
            .info {
                background-color: #fff;
                padding: px2rem(26px);
            }
            .title {
                font-size: px2rem(26px);
                font-weight: 800;
                color: #333;
                line-height: px2rem(30px);
                height: px2rem(60px);
                overflow: hidden;
                margin-bottom: 5px;
            }
            .p {
                border-top: 1px dashed #8f8f8f;
                padding-top: 8px;
                font-size: px2rem(24px);
                line-height: px2rem(34px);
                height: px2rem(68px);
                color: #888;
                margin-bottom: 8px;
                overflow: hidden;
            }
            .btn {
                width: px2rem(48px);
                height: px2rem(48px);
                text-align: center;
                line-height: px2rem(48px);
                color: #fff;
                background-color: $red;
                font-size: px2rem(24px);
            }
        }
    }
    .navigation-btn {
        text-align: center;
        padding: px2rem(20px);
        .btn {
            display: inline-block;
            vertical-align: top;
            background-color: #fff;
            width: px2rem(168px);
            height: px2rem(44px);
            color: #9f9f9f;
            font-size: px2rem(24px);
            outline: none;
            line-height: px2rem(44px);
            &.prev {
                transform: rotate(180deg);
            }
            &:active {
                background-color: $red;
                color: #fff;
            }
        }
    }
}
.product-article {
    background-color: #fff;
    padding: px2rem(40px);
    .hd {
        //display: flex;
        width: 100%;
        margin-bottom: px2rem(30px);
        font-size: 0;
        .wrapper {
            font-size: 0;
            &.fixed {
                position: fixed;
                width: 100%;
                top: 0;
                left: 0;
                background-color: #fff;
                z-index: 102;
                padding: px2rem(20px);
                box-sizing: border-box;
            }
        }
        .item {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            //flex: 1;
            position: relative;
            &:after {
                position: absolute;
                right: 0;
                top: 50%;
                width: 1px;
                height: px2rem(16px);
                margin-top: px2rem(-8px);
                content: '';
                background-color: #ccc;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
            .t {
                text-align: center;
                font-size: px2rem(30px);
                color: #333;
                height: px2rem(60px);
                line-height: px2rem(60px);
                a {
                    color: #333;
                }
            }
            &.on {
                .t {
                    color: #fff;
                    background: $red;
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
    .bd {
        .article {
            font-size: px2rem(28px);
            line-height: px2rem(46px);
            color: rgba(#5e5e5e, .8);
            margin-bottom: px2rem(30px);
            p {
                margin-bottom: px2rem(28px);
            }
            img {
                max-width: 100%;
                height: auto !important;
            }
        }
    }
}
.product-news-list {
    .swiper-slide {
        .item {
            display: block;
            background-color: #fff;
            padding: px2rem(20px) px2rem(30px);
            margin-bottom: px2rem(20px);
        }
        .top {
            display: flex;
            width: 100%;
            border-bottom: 1px dashed #d8d8d8;
            margin-bottom: px2rem(20px);
        }
        .t {
            flex: 1;
            overflow: hidden;
            display: flex;
            align-items: center;
            height: px2rem(60px);
            &:before {
                font-family: 'iconfont';
                content: '\e6cc';
                color: $red;
                font-size: px2rem(24px);
                margin-right: px2rem(10px);
            }
        }
        .tt {
            font-size: px2rem(30px);
            color: #333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .time {
            font-size: px2rem(18px);
            color: #666;
            line-height: px2rem(60px);
            margin-left: px2rem(10px);
        }
        .p {
            font-size: px2rem(26px);
            line-height: px2rem(40px);
            color: #777;
            max-height: px2rem(120px);
            overflow: hidden;
        }
    }
    .swiper-pagination {
        position: static;
        &-bullet {
            background-color: #fff;
            opacity: 1;
            &-active {
                background-color: $red;
            }
        }
    }
}
.product-sub-menu {
    //display: flex;
    //justify-content: space-around;
    //flex-wrap: wrap;
    font-size: 0;
    text-align: center;
    margin-bottom: px2rem(30px);
    width: 100%;
    a {
        display: inline-flex;
        vertical-align: top;
        padding: 0 px2rem(36px);
        font-size: px2rem(24px);
        height: px2rem(60px);
        line-height: px2rem(60px);
        color: #333;
        align-items: center;
        img {
            display: inline-block;
            vertical-align: top;
            width: px2rem(36px);
            height: px2rem(36px);
            margin-right: 2px;
        }
        &.on {
            border: 1px solid #dadada;
            border-radius: px2rem(4px);
            background-color: #fff;
        }
    }
}
.product-select-con {
    display: flex;
    margin-bottom: px2rem(30px);
    width: 100%;
    &.mb10 {
        margin-bottom: px2rem(10px);
    }
    .select {
        flex: 1;
        overflow: hidden;
        margin-right: px2rem(15px);
        position: relative;
        background-color: #fff;
        &:after {
            position: absolute;
            right: px2rem(15px);
            top: 50%;
            border-top: px2rem(6px) solid #535353;
            border-right: px2rem(6px) solid transparent;
            border-left: px2rem(6px) solid transparent;
            content: '';
            margin-top: px2rem(-6px);
        }
        &:last-child {
            margin-right: 0;
        }
        select {
            display: block;
            width: 100%;
            box-sizing: border-box;
            padding: 0 px2rem(20px);
            font-size: px2rem(24px);
            color: #4f4f4f;
            line-height: px2rem(64px);
            height: px2rem(64px);
            background: none;
            border: none;
            outline: none;
            appearance: none;
        }
    }
}
.map-con {
    .map-top {
        position: relative;
        padding-bottom: px2rem(24px);
        text-align: center;
        &:after {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: px2rem(24px);
            height: 2px;
            background: $red;
            margin-left: px2rem(-12px);
            content: '';
        }
        .t {
            font-size: px2rem(36px);
            color: #000;
            font-weight: bold;
        }
        .en {
            font-size: px2rem(36px);
            text-transform: uppercase;
            color: #000;
        }
        .p {
            margin-top: px2rem(25px);
            font-size: px2rem(24px);
            color: #929292;
        }
    }
    .map-img {
        text-align: center;
        padding: px2rem(20px) 0;
        img {
            max-width: px2rem(602px);
        }
        &.full {
            img {
                max-width: 100%;
            }
        }
    }
}
.map-tips {
    margin-bottom: px2rem(30px);
    font-size: 15px;
    color: #333;
}
.map-list {
    display: flex;
    width: 100%;
    font-size: 0;
    flex-wrap: wrap;
    padding-bottom: px2rem(30px);
    li {
        display: inline-flex;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        margin-bottom: px2rem(12px);
        flex-direction: column;
        &:nth-child(2n+1) {
            padding-right: px2rem(6px);
        }
        &:nth-child(2n) {
            padding-left: px2rem(6px);
        }
    }
    .wrapper {
        background-color: #fff;
        flex: 1;
    }
    .img {
        position: relative;
        width: 100%;
        padding-top: percentage(217/349);
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .info {
        padding: px2rem(20px);
    }
    .t {
        font-size: px2rem(26px);
        font-weight: bold;
        color: #000;
        margin-bottom: px2rem(15px);
    }
    .p {
        padding-top: px2rem(15px);
        border-top: 1px dashed #8f8f8f;
        font-size: px2rem(24px);
        line-height: px2rem(40px);
        color: #777;
        .address {
            position: relative;
            padding-left: px2rem(30px);
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                font-family: 'iconfont';
                content: '\e69a';
                font-size: px2rem(26px);
                color: $red;
            }
        }
        .phone {
            position: relative;
            padding-left: px2rem(30px);
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                font-family: 'iconfont';
                content: '\e6f5';
                font-size: px2rem(26px);
                color: $red;
            }
        }
    }
}
.map-outside-list {
    li {
        margin-bottom: px2rem(20px);
    }
    .wrapper {
        display: flex;
        background-color: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: px2rem(30px);
    }
    .img {
        width: px2rem(310px);
        margin-right: px2rem(20px);
        span {
            display: block;
            width: 100%;
            padding-top: percentage(200/310);
            overflow: hidden;
            position: relative;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
    }
    .info {
        flex: 1;
        overflow: hidden;
        padding-top: px2rem(12px);
    }
    .t {
        font-size: px2rem(24px);
        font-weight: bold;
        color: #333;
        margin-bottom: px2rem(15px);
    }
    .p {
        border-top: 1px dashed #b6b6b6;
        padding-top: px2rem(15px);
        font-size: px2rem(24px);
        line-height: px2rem(32px);
        color: #999;
        .address {
            position: relative;
            padding-left: px2rem(30px);
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                font-family: 'iconfont';
                content: '\e6f7';
                font-size: px2rem(26px);
                line-height: px2rem(32px);
                color: $red;
            }
        }
        .country {
            position: relative;
            padding-left: px2rem(30px);
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                font-family: 'iconfont';
                content: '\e6f6';
                font-size: px2rem(26px);
                line-height: px2rem(32px);
                color: $red;
            }
        }
    }
}
.product-select-input {
    display: flex;
    width: 100%;
    background-color: #fff;
    margin-bottom: px2rem(40px);
    button {
        width: px2rem(70px);
        height: px2rem(64px);
        text-align: center;
        line-height: px2rem(64px);
        background: $red;
        color: #fff;
        appearance: none;
        border: none;
        font-size: px2rem(30px);
        font-weight: bold;
    }
    .input {
        flex: 1;
        overflow: hidden;
        input {
            display: block;
            width: 100%;
            height: px2rem(64px);
            box-sizing: border-box;
            padding: 0 px2rem(20px);
            font-size: px2rem(24px);
            color: #444;
            background: none;
            appearance: none;
            border: none;
        }
    }
}
.product-list {
    font-size: 0;
    width: 100%;
    padding-bottom: px2rem(25px);
    li {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        margin-bottom: px2rem(14px);
        &:nth-child(2n+1) {
            padding-right: px2rem(7px);
        }
        &:nth-child(2n) {
            padding-left: px2rem(7px);
        }
        .wp {
            display: block;
            //background-color: #fff;
            width: 100%;
        }
    }
    .img {
        width: 100%;
        padding-top: percentage(215/350);
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
    .info {
        padding: px2rem(25px) px2rem(25px) px2rem(40px);
    }
    .t {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: px2rem(26px);
        font-weight: bold;
        color: #333;
        margin-bottom: px2rem(18px);
    }
    .p {
        padding-top: px2rem(15px);
        font-size: px2rem(24px);
        line-height: px2rem(40px);
        color: #777;
        height: px2rem(40px) * 4;
        overflow: hidden;
        margin-bottom: px2rem(10px);
        border-top: 1px dashed #8f8f8f;
    }
    .arrow {
        text-align: center;
        width: px2rem(50px);
        height: px2rem(50px);
        line-height: px2rem(50px);
        color: #fff;
        background: $red;
        font-size: px2rem(26px);
    }
    .hide-link {
        overflow: hidden;
        height: 0;
    }
}
.product-page {
    text-align: center;
    .btn {
        display: inline-block;
        vertical-align: top;
        background-color: #fff;
        width: px2rem(168px);
        height: px2rem(44px);
        color: #9f9f9f;
        font-size: px2rem(24px);
        outline: none;
        line-height: px2rem(44px);
        &.prev {
            transform: rotate(180deg);
        }
        &:hover {
            background-color: $red;
            color: #fff;
        }
    }
}
.service-detail-con {
    background-color: #eeeff0;
    overflow: hidden;
}
.service-detail-banner {
    width: 100%;
    img {
        display: block;
        width: 100%;
    }
}
.service-detail-top {
    background-color: #fff;
    padding: px2rem(50px);
    margin-bottom: px2rem(20px);
    .title {
        font-weight: bold;
        font-size: 16px;
        color: #333;
        margin-bottom: px2rem(20px);
        padding-left: 30px;
        background-image: url(../images/product_icon_31.png);
        background-position: left top;
        background-size: 25px auto;
        background-repeat: no-repeat;
    }
    .p {
        border-top: 1px dashed #8b8b8b;
        padding-top: px2rem(20px);
        font-size: 14px;
        line-height: 24px;
        color: #777;
    }
    .tags {
        margin-top: px2rem(40px);
        font-size: 14px;
        color: #777;
        a {
            color: #777;
        }
    }
}
.service-info {
    background-color: #fff;
    margin-bottom: px2rem(50px);
    padding: px2rem(50px);
    .top {
        position: relative;
        text-align: center;
        margin-bottom: px2rem(30px);
        &:after {
            position: absolute;
            width: 100%;
            height: 1px;
            background: #e5e5e5;
            content: '';
            z-index: 1;
            left: 0;
            top: 50%;
        }
        .t {
            display: inline-block;
            vertical-align: top;
            padding: 0 px2rem(24px);
            background-color: #fff;
            position: relative;
            z-index: 2;
            font-size: px2rem(36px);
            color: #333;
        }
    }
    .article {
        font-size: 14px;
        line-height: 24px;
        color: #5e5e5e;
        p {
            margin-bottom: 14px;
        }
        img {
            max-width: 100%;
            height: auto !important;
        }
    }
}
.service-case {
    margin-bottom: px2rem(50px);
    padding: 0 px2rem(20px);
    .top {
        position: relative;
        text-align: center;
        margin-bottom: px2rem(30px);
        &:after {
            position: absolute;
            width: 100%;
            height: 1px;
            background: #e5e5e5;
            content: '';
            z-index: 1;
            left: 0;
            top: 50%;
        }
        .t {
            display: inline-block;
            vertical-align: top;
            padding: 0 px2rem(24px);
            background-color: #eeeff0;
            position: relative;
            z-index: 2;
            font-size: px2rem(36px);
            color: #333;
        }
    }
}
.service-case-swiper {
    .swiper-slide {
        a {
            display: block;
            background-color: #fff;
        }
        .img {
            width: 100%;
            padding-top: percentage(215/350);
            position: relative;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
        .info {
            padding: px2rem(25px) px2rem(25px) px2rem(40px);
        }
        .t {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: px2rem(26px);
            font-weight: bold;
            color: #333;
            margin-bottom: px2rem(18px);
        }
        .p {
            padding-top: px2rem(15px);
            font-size: px2rem(24px);
            line-height: px2rem(34px);
            color: #777;
            height: px2rem(34px) * 4;
            overflow: hidden;
            margin-bottom: px2rem(10px);
            border-top: 1px dashed #8f8f8f;
        }
        .arrow {
            text-align: center;
            width: px2rem(50px);
            height: px2rem(50px);
            line-height: px2rem(50px);
            color: #fff;
            background: $red;
            font-size: px2rem(26px);
        }
    }
    .swiper-pagination {
        position: static;
        margin-top: px2rem(20px);
        &-bullet {
            opacity: 1;
            background-color: #a6a6a6;
            &-active {
                background-color: $red;
            }
        }
    }
}
.service-form {
    margin-bottom: px2rem(50px);
    padding: 0 px2rem(20px);
    .top {
        position: relative;
        text-align: center;
        margin-bottom: px2rem(30px);
        &:after {
            position: absolute;
            width: 100%;
            height: 1px;
            background: #e5e5e5;
            content: '';
            z-index: 1;
            left: 0;
            top: 50%;
        }
        .t {
            display: inline-block;
            vertical-align: top;
            padding: 0 px2rem(24px);
            background-color: #eeeff0;
            position: relative;
            z-index: 2;
            font-size: px2rem(36px);
            color: #333;
        }
    }
}
.base-detail-img {
    position: relative;
    overflow: hidden;
    img {
        display: block;
        width: 100%;
    }
    .t {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: $red;
        color: #fff;
        line-height: 50px;
        font-size: 18px;
        padding: 0 25px;
        border-top-left-radius: 10px;
        max-width: 100%;
        box-sizing: border-box;
        overflow: hidden;
    }
}
.base-detail-row {
    display: flex;
}
.base-detail-cell {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: px2rem(220px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .t {
        font-size: px2rem(26px);
        color: #333;
        img {
            height: px2rem(44px);
            vertical-align: baseline;
        }
    }
    .p {
        margin-top: px2rem(20px);
        font-size: px2rem(20px);
        color: #346ef1;
    }
}
.base-detail-intro {
    background-color: #fff;
    padding: px2rem(80px) px2rem(40px) px2rem(40px);
    .title {
        display: flex;
        padding-bottom: px2rem(30px);
        border-bottom: 1px dashed #ddd;
        img {
            height: px2rem(47px);
            margin-right: px2rem(20px);
        }
        .t {
            flex: 1;
            overflow: hidden;
            font-size: px2rem(35px);
            color: #000;
            line-height: px2rem(47px);
        }
    }
    .article {
        font-size: 14px;
        line-height: 2;
        color: #888;
        padding-top: px2rem(30px);
        img {
            max-width: 100%;
        }
        p {
            margin-bottom: 15px;
        }
    }
    .address {
        font-size: 14px;
        line-height: 2;
        color: #888;
    }
}
.base-detail-map {
    .map-link {
        display: inline-block;
        vertical-align: top;
        background: $red;
        color: #fff;
        padding: 0 px2rem(40px);
        font-size: 12px;
        line-height: 36px;
        border-top-right-radius: 10px;
    }
    .map-box {
        width: 100%;
        height: px2rem(440px);
    }
}
.base-detail-info {
    background-color: #fff;
    padding: px2rem(50px);
    .top {
        position: relative;
        text-align: center;
        margin-bottom: px2rem(50px);
        &:after {
            position: absolute;
            width: 100%;
            height: 1px;
            background: #e5e5e5;
            content: '';
            z-index: 1;
            left: 0;
            top: 50%;
        }
        .t {
            display: inline-block;
            vertical-align: top;
            padding: 0 px2rem(24px);
            background-color: #fff;
            position: relative;
            z-index: 2;
            font-size: px2rem(36px);
            color: #333;
        }
    }
    .article {
        font-size: 14px;
        line-height: 24px;
        color: #5e5e5e;
        p {
            margin-bottom: 14px;
        }
        img {
            max-width: 100%;
            height: auto !important;
        }
    }
}
.base-detail-more {
    background-color: #f5f5f5;
    padding: px2rem(50px) px2rem(40px) px2rem(90px);
    .top {
        position: relative;
        text-align: center;
        margin-bottom: px2rem(50px);
        &:after {
            position: absolute;
            width: 100%;
            height: 1px;
            background: #e5e5e5;
            content: '';
            z-index: 1;
            left: 0;
            top: 50%;
        }
        .t {
            display: inline-block;
            vertical-align: top;
            padding: 0 px2rem(24px);
            background-color: #f5f5f5;
            position: relative;
            z-index: 2;
            font-size: px2rem(36px);
            color: #333;
        }
    }
}
.base-detail-swiper {
    .swiper-slide {
        background-color: #fff;
        height: auto;
    }
    .wrapper {
        display: block;
    }
    .img {
        position: relative;
        width: 100%;
        padding-top: percentage(202/325);
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
    .info {
        padding: px2rem(30px);
    }
    .t {
        font-size: 14px;
        color: #333;
        margin-bottom: 5px;
    }
    .p {
        font-size: 12px;
        line-height: 22px;
        color: #666;
    }
    .swiper-pagination {
        position: static;
        margin-top: px2rem(40px);
        &-bullet {
            opacity: 1;
            background: #999;
            &-active {
                background: $red;
            }
        }
    }
}
.base-detail-form {
    padding: px2rem(90px) px2rem(40px);
    background-color: #eeeff1;
    margin-bottom: 0;
}