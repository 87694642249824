.head {
    width: 100%;
    position: relative;
    height: px2rem(100px);
    background: #fff;
    padding: 0 px2rem(20px);
    box-sizing: border-box;
    .logo {
        display: inline-block;
        vertical-align: top;
        height: 100%;
        width: (220/750)*100%;
        img {
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
            max-width: 100%;
            max-height: 100%;
        }
    }
    .text {
        display: inline-block;
        vertical-align: top;
        font-size: px2rem(24px);
        padding: 0 px2rem(30px);
        line-height: px2rem(100px);
        color: #666;
    }
    .language {
        display: inline-block;
        padding: 0 px2rem(20px);
        font-size: px2rem(36px);
        line-height: px2rem(100px);
        float: right;
        color: #666;
    }
    .menu {
        float: right;
        line-height: px2rem(100px);
        font-size: px2rem(46px);
        padding: 0 px2rem(20px);
        color: #666;
    }
}
.moblie-nav {
    display: none;
    z-index: 1000;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #fff;
    &-top {
        position: relative;
        height: px2rem(100px);
        width: 100%;
        background: $red;
        .back {
            display: inline-block;
            font-size: px2rem(28px);
            line-height: px2rem(100px);
            color: #fff;
            padding-left: px2rem(75px);
            background: url(../images/moblie-nav-0.png) no-repeat center left;
            background-size: px2rem(20px);
            background-position-x: px2rem(35px);
        }
    }
    &-form {
        position: relative;
        background: #ebe9ea;
        width: 100%;
        height: px2rem(90px);
        padding-right: px2rem(120px);
        box-sizing: border-box;
        .input-box {
            width: 100%;
            height: 100%;
            padding-left: px2rem(35px);
            box-sizing: border-box;
            position: relative;
            input {
                height: 100%;
                width: 100%;
                border: 0px;
                background: #ebe9ea;
                font-size: px2rem(24px);
                color: #636363;
            }
        }
        button {
            border: 0px;
            position: absolute;
            right: 0px;
            top: 0;
            width: px2rem(85px);
            height: px2rem(90px);
            background: url(../images/moblie-nav-1.png) no-repeat;
            background-size: 100% 100%;
        }
    }
    &-scroll {
        overflow: hidden;
        position: absolute;
        top: px2rem(190px);
        bottom: 0px;
        left: 0px;
        right: 0px;
    }
    &-list {
        width: 100%;
        padding: px2rem(10px) px2rem(35px);
        box-sizing: border-box;
        li {
            width: 100%;
            min-height: px2rem(94px);
            &.on {
                .a {
                    i {
                        &:before {
                            opacity: 0;
                        }
                    }
                }
                .sub {
                    display: block;
                }
            }
            .a {
                position: relative;
                a {
                    position: relative;
                    z-index: 10;
                    padding-right: px2rem(40px);
                    display: inline-block;
                }
                i {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        right: px2rem(20px);
                        width: px2rem(26px);
                        height: px2rem(4px);
                        background: #666;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        right: px2rem(30px);
                        width: px2rem(4px);
                        height: px2rem(26px);
                        background: #666;
                    }
                }
            }
            .a {
                display: block;
                font-size: px2rem(28px);
                text-transform: uppercase;
                box-sizing: border-box;
                border-bottom: 1px solid #e3e3e3;
                color: #555;
                line-height: px2rem(60px);
                padding-top: px2rem(25px);
                padding-bottom: px2rem(25px);
            }
            .sub-item {
                padding-left: px2rem(60px);
                box-sizing: border-box;
                border-bottom: 1px solid #e3e3e3;
                a {
                    color: #555;
                    display: block;
                    font-size: px2rem(28px);
                    text-transform: uppercase;
                    line-height: px2rem(60px);
                    padding-top: px2rem(25px);
                    padding-bottom: px2rem(25px);
                }
                &.on {
                    .child {
                        display: block;
                    }
                    .a-block {
                        i {
                            &:before {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
            .a-block {
                display: block;
                position: relative;
                i {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    z-index: 2;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        right: px2rem(20px);
                        width: px2rem(26px);
                        height: px2rem(4px);
                        background: #666;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        right: px2rem(30px);
                        width: px2rem(4px);
                        height: px2rem(26px);
                        background: #666;
                    }
                    + a {
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        z-index: 5;
                        box-sizing: border-box;
                        margin-right: 30px;
                    }
                }
            }
            a {
                //line-height: px2rem(100px);
                img {
                    padding-right: px2rem(20px);
                    width: px2rem(36px);
                    vertical-align: middle;
                }
            }
            .sub,
            .child {
                display: none;
            }
            > a {
                font-weight: bold;
            }
            .item {
                border-top: 1px solid #e3e3e3;
            }
        }
    }
}