.manage-article {
    background-color: #fff;
    padding: px2rem(60px) px2rem(34px);
    border-radius: px2rem(8px);
    font-size: px2rem(24px);
    line-height: px2rem(40px);
    color: #888;
    img {
        max-width: 100%;
    }
    p {
        margin-bottom: px2rem(30px);
    }
    .title {
        position: relative;
        font-size: px2rem(34px);
        color: #333;
        padding-bottom: px2rem(15px);
        margin-bottom: px2rem(15px);
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: px2rem(26px);
            height: px2rem(4px);
            background: $red;
        }
    }
    .b {
        font-size: px2rem(26px);
        color: #000;
        margin-bottom: px2rem(15px);
    }
}
.manage-list {
    li {
        margin-top: px2rem(40px);
        background-color: #fff;
        border-radius: px2rem(10px);
        padding: px2rem(45px);
    }
    .img {
        margin-bottom: px2rem(34px);
        img {
            display: block;
            width: 100%;
        }
    }
    .t {
        text-align: center;
        font-size: px2rem(28px);
        color: #000;
        margin-bottom: px2rem(15px);
    }
    .p {
        font-size: px2rem(24px);
        line-height: px2rem(40px);
        color: #888;
        text-align: center;
        p {
            margin-bottom: px2rem(15px);
        }
    }
}
.health-des {
    font-size: px2rem(24px);
    line-height: px2rem(40px);
    color: #888;
    text-align: center;
}
.health-list {
    li {
        margin-top: px2rem(50px);
        &:nth-child(7n+1),
        &:nth-child(7n+5) {
            .img-box {
                &:after {
                    background: #01ae42;
                }
            }
            .t {
                color: #01ae42;
            }
        }
        &:nth-child(7n+2),
        &:nth-child(7n+7) {
            .img-box {
                &:after {
                    background: #ff8113;
                }
            }
            .t {
                color: #ff8113;
            }
        }
        &:nth-child(7n+3),
        &:nth-child(7n+6) {
            .img-box {
                &:after {
                    background: #7c858c;
                }
            }
            .t {
                color: #7c858c;
            }
        }
        &:nth-child(7n+4) {
            .img-box {
                &:after {
                    background: #0059b3;
                }
            }
            .t {
                color: #0059b3;
            }
        }
    }
    .img-box {
        margin: 0 px2rem(30px) px2rem(-226px);
        padding-bottom: px2rem(30px);
        padding-right: px2rem(30px);
        box-sizing: border-box;
        position: relative;
        z-index: 2;
        overflow: hidden;
        &:after {
            position: absolute;
            top: px2rem(30px);
            left: px2rem(30px);
            right: 0;
            bottom: 0;
            content: '';
            border-radius: px2rem(20px);
        }
    }
    .img {
        width: 100%;
        position: relative;
        padding-top: percentage(377/620);
        overflow: hidden;
        border-radius: px2rem(20px);
        z-index: 2;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
    .info {
        background-color: #fff;
        border-radius: px2rem(20px);
        padding: px2rem(260px) px2rem(30px) px2rem(60px);
    }
    .t {
        font-size: px2rem(32px);
        margin-bottom: px2rem(15px);
    }
    .p {
        font-size: px2rem(24px);
        line-height: px2rem(40px);
        color: #888;
    }
}