.development-article {
    padding: px2rem(50px) px2rem(30px) px2rem(30px);
    font-size: px2rem(24px);
    line-height: 2;
    color: #777;
    background-color: #f5f5f5;
    text-align: center;
}
.development-swiper {
    .swiper-slide {
        position: relative;
        .img {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: percentage(430/750);
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
        .word {
            position: absolute;
            z-index: 2;
            top: px2rem(50px);
            left: px2rem(40px);
            width: px2rem(610px);
            color: #fff;
        }
        .t {
            font-size: px2rem(28px);
            font-weight: bold;
            margin-bottom: px2rem(15px);
        }
        .p {
            font-size: px2rem(24px);
            line-height: 2;
        }
    }
    .swiper-pagination {
        &-bullet {
            opacity: 1;
            background: #fff;
            &-active {
                background: $red;
            }
        }
    }
}
.development-report {
    padding: px2rem(50px) px2rem(30px) px2rem(100px);
}
.development-page-list {
    font-size: 0;
    margin: 0 px2rem(-5px);
    padding-bottom: px2rem(50px);
    padding-top: px2rem(30px);
    li {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        margin-bottom: px2rem(10px);
        padding: 0 px2rem(5px);
    }
    .wrapper {
        display: block;
        background-color: #f7f7f7;
        border: 1px solid #e1e1e1;
    }
    .img {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: percentage(490/348);
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
    .p {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: px2rem(24px);
        line-height: px2rem(80px);
        color: #666;
    }
}
.development-more-btn {
    display: block;
    margin: 0 auto;
    width: px2rem(310px);
    height: px2rem(70px);
    background: $red;
    line-height: px2rem(70px);
    font-size: px2rem(24px);
    color: #fff;
    text-align: center;
    .up {
        display: none;
    }
    &.on {
        .down {
            display: none;
        }
        .up {
            display: inline;
        }
    }
}