/* CSS Document */
@import "//at.alicdn.com/t/font_587390_ux6zcinx8bd.css";
//SCSS全局变量
$red: #f00;
// 默认移动端设计稿宽度
$baseDevice: 750;
// 前端切图移动端默认正常显示尺寸，默认为设计稿的一半
$device: $baseDevice / 2;
// 默认html font-size
$baseFontSize: 100px;
// scss function
@function calc-percent($target, $context) {
    @return $target/$context * 100%;
}
@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
    @if (unitless($px)) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
        @return px2rem($px + 0px); // That may fail.
    } @else if (unit($px)==rem) {
        @return $px;
    }
    @return ($px / $base-font-size) * 1rem;
}
//透明
@mixin touming($o: 70) {
    opacity: $o/100;
    -webkit-opacity: $o/100;
    filter: alpha(opacity=$o);
}
@mixin transition($time: .2s) {
    -webkit-transition: $time ease all;
    -moz-transition: $time ease all;
    transition: $time ease all;
}
@mixin articleReset($fontsize: 14px, $lineHieght: 30px) {
    font-size: $fontsize;
    p {
        margin-bottom: $lineHieght/2;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    ul {
        list-style: disc;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        li {
            list-style: decimal;
        }
    }
}
// 多行省略
@mixin multiEllipsis($line: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}
/* reset.css V1.6  Start*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
th,
td {
    font-weight: normal;
    margin: 0;
    padding: 0;
}
body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
i {
    font: normal 12px BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    &::-ms-expand {
        display: none;
    }
}
ul,
ol,
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
}
a:hover {
    text-decoration: none;
}
img {
    vertical-align: top;
    border: 0;
}
button,
input,
textarea {
    font-size: 100%;
    vertical-align: middle;
    outline: none;
    border-radius: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
div,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
html {
    font-size: $baseFontSize;
}
@media screen and (min-width: 320px) {
    html {
        font-size: (320/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 360px) {
    html {
        font-size: (360/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 375px) {
    html {
        font-size: (375/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 400px) {
    html {
        font-size: (400/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 480px) {
    html {
        font-size: (480/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 640px) {
    html {
        font-size: (640/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 750px) {
    html {
        font-size: (750/$device)*$baseFontSize;
    }
}
.clearfloat:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ' ';
}
.clearfloat {
    *zoom: 1;
}
.clearboth {
    clear: both;
}
.wp {
    width: 1000px;
    margin: 0 auto;
}
.checkBrowser {
    font-size: 14px;
    line-height: 40px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 700px;
    height: 40px;
    margin-left: -360px;
    padding: 0 10px;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e6212a;
    a {
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
    }
}
/* reset.css V1.6  End */
// page结构以及CSS样式
// *******************
// ********************
// ********************
// HTML结构
// <div class="paged">
//  <a href="#">首页</a>
//  <a class="">上一页</a>
//  <span class="current">1</span>
//  <a href="#">2</a>
//  <a class="" href="#">下一页</a>
//  <a href="#">尾页</a>
// </div>
// ********************
// ********************
// ********************
// CSS样式
// .paged { padding-top: 30px; text-align: center; }
// .paged a, .paged .p_info, .paged .current { display: inline-block; zoom: 1; *display:inline;
// margin: 0px 3px; padding: 5px 10px; font-size: 12px; text-align: center; border: 1px solid #cfcfcf; background: #ffffff; color: #333; }
// .paged .current, .paged a:hover { border: 1px solid #076da8; background: #147ab5; color: #fff; }
.bread {
    padding: px2rem(20px) px2rem(10px);
    font-size: px2rem(24px);
    color: #333;
    line-height: px2rem(40px);
    border-top: 1px solid #e5e5e5;
    a {
        display: inline-block;
        vertical-align: top;
    }
    h1 {
        display: inline-block;
        vertical-align: top;
        line-height: px2rem(40px);
        font-size: px2rem(24px);
    }
    h2 {
        display: inline-block;
        vertical-align: top;
        line-height: px2rem(40px);
        font-size: px2rem(24px);
        color: #333;
    }
}
.fast-link {
    li {
        border-bottom: 1px solid #e3e3e3;
        a {
            display: flex;
            padding: 0 px2rem(30px);
            align-items: center;
            height: px2rem(110px);
            font-size: px2rem(28px);
            color: #555;
        }
        img {
            display: block;
            transform: rotate(180deg);
            height: px2rem(44px);
            margin-right: px2rem(20px);
        }
    }
}
.go-top {
    position: fixed;
    right: 0;
    bottom: px2rem(150px);
    width: px2rem(60px);
    height: px2rem(60px);
    text-align: center;
    line-height: px2rem(60px);
    color: #fff;
    background: $red;
    font-size: px2rem(32px);
    z-index: 202;
}