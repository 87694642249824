.footer-top {
    .footer-list {
        padding-bottom: px2rem(20px);
        .nav-a {
            display: none;
            border-bottom: 1px solid #e3e3e3;
            padding: 0 px2rem(20px);
            .a {
                padding-left: px2rem(74px);
                &:last-child {
                    border-bottom: 0px;
                }
            }
        }
        .a {
            padding: 0 px2rem(30px) 0 px2rem(40px);
            position: relative;
            a {
                position: relative;
                z-index: 10;
                padding-right: px2rem(40px);
                display: inline-block;
                line-height: px2rem(100px);
                img {
                    padding-right: px2rem(10px);
                    width: px2rem(36px);
                    vertical-align: middle;
                }
            }
            i {
                position: absolute;
                top: 0px;
                left: 0px;
                right: px2rem(30px);
                height: 100%;
                display: block;
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    right: px2rem(20px);
                    width: px2rem(26px);
                    height: px2rem(4px);
                    background: #666;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    right: px2rem(30px);
                    width: px2rem(4px);
                    height: px2rem(26px);
                    background: #666;
                }
            }
        }
        .a {
            display: block;
            font-size: px2rem(28px);
            text-transform: uppercase;
            line-height: px2rem(110px);
            box-sizing: border-box;
            border-bottom: 1px solid #e3e3e3;
            color: #555;
        }
    }
    li:last-child {
        .a {
            border-bottom: 0px;
        }
    }
}
.footer-bottom {
    background: #4e4e4e;
    padding: px2rem(30px) px2rem(20px) px2rem(40px);
    color: #f9f9f9;
    text-align: center;
    line-height: px2rem(48px);
    font-size: px2rem(24px);
}