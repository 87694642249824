.dn {
    display: none;
}
body, html {
    max-width: 750px;
    position: relative;
    margin: 0 auto;
}
.sub-banner {
    position: relative;
    z-index: 30;
    width: 100%;
    padding-bottom: (430/750)*100%;
    .banner-box {
        overflow: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        a {
            display: block;
            .img {
                width: 100%;
                height: 100%;
                background: #fff none no-repeat center center;
                background-size: cover;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .index-pagination {
        position: absolute;
        top: auto;
        bottom: px2rem(30px);
        left: 0;
        text-align: center;
        width: 100%;
        z-index: 10;
        span {
            margin: 0 4px;
            opacity: 1;
            background: #fff;
            width: 8px;
            height: 8px;
            &.swiper-pagination-bullet-active {
                background: $red;
            }
        }
    }
}
.article-nav {
    &-box {
        position: relative;
        z-index: 20;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        height: 60px;
        background: #fff;
        border-bottom: 1px solid #e5e5e5;
        font-size: 0px;
    }
    .nav3-scroll {
        position: absolute;
        top: 0px;
        left: 0px;
        text-align: center;
    }
    .scroll {
        text-align: center;
    }
    a {
        box-sizing: border-box;
        padding: 0 px2rem(30px);
        display: inline-block;
        vertical-align: top;
        height: 60px;
        text-align: center;
        + a {
            position: relative;
            &:after {
                content: "";
                width: 1px;
                height: 34%;
                position: absolute;
                left: 0px;
                top: 50%;
                background: #e5e5e5;
                transform: translate(0, -50%);
            }
        }
        &.on {
            background: $red;
            span {
                color: #fff;
            }
            .imgHover {
                display: inline-block;
            }
            .img {
                display: none;
            }
        }
        .img, .imgHover {
            margin-right: 2px;
            display: inline-block;
            vertical-align: middle;
            height: 100%;
            width: 20px;
            background: no-repeat center center;
            background-size: 100%;
        }
        .imgHover {
            display: none;
        }
        span {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            color: #666;
        }
    }
}
.gray-bg {
    background: #f9f9f9;
    padding: px2rem(50px) px2rem(20px) px2rem(70px);
}
.load-more {
    text-align: center;
    .box {
        display: inline-block;
        font-size: 14px;
        border: 1px solid #d2d2d2;
        color: #888;
        line-height: 26px;
        padding: 0 24px;
    }
}
.year-components {
    position: relative;
    height: 80px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .next {
        z-index: 10;
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 46px;
        transform: rotate(180deg);
        width: 12px;
        height: 24px;
        background: url(../images/year_icon_1.png) no-repeat;
        background-size: 100%;
    }
    .prev {
        z-index: 10;
        cursor: pointer;
        position: absolute;
        left: 0px;
        top: 46px;
        width: 12px;
        height: 24px;
        background: url(../images/year_icon_1.png) no-repeat;
        background-size: 100%;
    }
    .year-swiper {
        position: relative;
        height: 100%;
        width: 100%;
        &-box {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            .swiper-slide {
                cursor: pointer;
                position: relative;
                width: (1/5)*100%;
                height: 100%;
                display: block;
                &.on {
                    .year-box {
                        .text {
                            color: $red;
                        }
                        .radius {
                            position: relative;
                            left: 50%;
                            width: 20px;
                            height: 20px;
                            border: 1px solid $red;
                            margin-left: -10px;
                            top: 48px;
                            background: #fff;
                            &:after {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                margin-left: -3px;
                                margin-top: -3px;
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                background: $red;
                            }
                        }
                    }
                }
                .year {
                    position: relative;
                    margin: 0 auto;
                    width: 46px;
                    height: 100%;
                    &-box {
                        .text {
                            position: absolute;
                            top: 18px;
                            left: 50%;
                            white-space: nowrap;
                            transform: translate(-50%, 0);
                            font-size: 12px;
                            color: #333;
                        }
                        .radius {
                            background: #fff;
                            position: absolute;
                            left: 50%;
                            margin-left: -10px;
                            top: 50px;
                            width: 16px;
                            height: 16px;
                            border: 1px solid #bbb;
                            box-sizing: border-box;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
        .year-bg {
            position: absolute;
            height: 4px;
            top: 56px;
            left: 0px;
            width: 100%;
            padding: 0 40px;
            box-sizing: border-box;
            &-box {
                width: 100%;
                height: 100%;
                background: url(../images/bg.png) left top;
            }
        }
    }
}
.page {
    text-align: center;
    font-size: 0px;
    span,
    a {
        margin: 0 2px;
        display: inline-block;
        padding: 0 14px;
        line-height: 36px;
        background: #fff;
        font-size: 14px;
        color: #d2d2d2;
        border: 1px solid #d2d2d2;
        transition: all .3s ease;
        &.on,
        &:hover {
            border-color: $red;
            color: $red;
        }
    }
    &.news-page {
        display: flex;
        span,
        a {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
.nav3 {
    position: relative;
    width: 100%;
    height: 60px;
    overflow: hidden;
    &-scroll {
        position: absolute;
        left: 0px;
        top: 0px;
    }
}
.nav2 {
    overflow-x: auto;
}
.nav2, .nav3 {
    white-space: nowrap;
    text-align: center;
    padding-bottom: px2rem(20px);
    a {
        position: relative;
        display: inline-block;
        padding: 0 10px;
        &.on span,
        &.on h2 {
            color: $red;
        }
        img {
            margin-right: 2px;
            width: 20px;
        }
        img, span {
            color: #666;
            font-size: 12px;
            vertical-align: middle;
        }
        + a {
            &:after {
                content: "";
                width: 1px;
                height: 80%;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0px;
                background: #666;
            }
        }
    }
}
.nav3 {
    a {
        padding: 0 10px;
        img {
            width: 24px;
        }
        span,
        h2 {
            min-width: 5em;
            margin-top: px2rem(20px);
            display: block;
            font-size: 12px;
        }
        + a {
            &:after {
                background: #e0e0e0;
            }
        }
    }
}
