.responsibility-top {
    .img {
        display: block;
        img {
            display: block;
            width: 100%;
        }
    }
    .title {
        padding: px2rem(30px) 0 px2rem(20px);
        font-size: px2rem(30px);
        color: #333;
        font-weight: bold;
        a {
            color: #333;
        }
    }
    .p {
        font-size: px2rem(24px);
        line-height: px2rem(40px);
        color: #888;
        padding-top: px2rem(20px);
        border-top: 1px dashed #8e8e8e;
    }
    .more {
        display: block;
        width: px2rem(310px);
        height: px2rem(70px);
        line-height: px2rem(70px);
        color: #fff;
        text-align: center;
        font-size: px2rem(24px);
        margin-top: px2rem(40px);
        background: $red;
    }
}
.responsibility-list {
    background-color: #fff;
    .wrapper {
        padding: px2rem(30px);
        background-color: #fff;
        display: flex;
        align-items: center;
    }
    .img {
        width: px2rem(300px);
        height: px2rem(205px);
        overflow: hidden;
        margin-right: px2rem(30px);
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .info {
        flex: 1;
        overflow: hidden;
    }
    .t {
        font-size: px2rem(28px);
        font-weight: bold;
        color: #333;
        line-height: px2rem(40px);
        @include multiEllipsis();
    }
    .p {
        font-size: px2rem(24px);
        line-height: px2rem(40px);
        color: #888;
        @include multiEllipsis();
    }
}