.team-article {
    font-size: px2rem(24px);
    line-height: 2;
    color: #777;
    margin-bottom: px2rem(15px);
    img {
        max-width: 100%;
        height: auto;
    }
}
.team-title {
    text-align: center;
    position: relative;
    font-size: px2rem(42px);
    color: #333;
    //padding-bottom: px2rem(20px);
    margin-bottom: px2rem(20px);
    line-height: 2;
    &:before {
        position: absolute;
        bottom: 0;
        left: 50%;
        content: '';
        background: $red;
        width: px2rem(24px);
        height: px2rem(4px);
        margin-left: px2rem(-12px);
    }
}
.team-icon-con {
    .img {
        img {
            display: block;
            width: 100%;
        }
    }
    .list {
        background-color: #fff;
        li {
            padding: px2rem(30px);
            border-bottom: 1px solid #eee;
            display: flex;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .icon {
        width: px2rem(100px);
        height: px2rem(100px);
        margin-right: px2rem(20px);
        img {
            width: 100%;
            height: 100%;
        }
    }
    .des {
        flex: 1;
        overflow: hidden;
        padding-top: px2rem(30px);
    }
    .t {
        font-size: px2rem(32px);
        color: #000;
        margin-bottom: px2rem(10px);
    }
    .p {
        font-size: px2rem(24px);
        line-height: px2rem(40px);
        color: #777;
    }
}
.team-health {
    background-color: #fff;
    img {
        display: block;
        width: 100%;
    }
    .info {
        padding: px2rem(50px) px2rem(30px);
        line-height: 2;
    }
    .p {
        font-size: px2rem(24px);
        color: #777;
        text-align: center;
    }
}
.team-balance {
    .des {
        text-align: center;
        font-size: px2rem(24px);
        line-height: 2;
        color: #777;
        margin-bottom: px2rem(30px);
    }
    &-swiper {
        .swiper-slide {
            background-color: #fff;
            .img {
                position: relative;
                width: 100%;
                padding-top: percentage(339/710);
                overflow: hidden;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }
            .info {
                padding: px2rem(30px);
            }
            .t {
                font-size: px2rem(32px);
                color: #333;
                margin-bottom: px2rem(10px);
            }
            .p {
                font-size: px2rem(24px);
                line-height: px2rem(40px);
                color: #777;
            }
        }
        .swiper-pagination {
            position: static;
            margin-top: px2rem(30px);
            &-bullet {
                opacity: 1;
                background: #fff;
                &-active {
                    background: $red;
                }
            }
        }
    }
}