.special-event {
    .top-box {
        background-image: url(../images/special_bg1.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        padding: px2rem(50px) 0 px2rem(30px);
        .title {
            text-align: center;
            color: #fff;
            margin-bottom: px2rem(30px);
            .cn {
                font-size: px2rem(36px);
            }
            .en {
                font-size: px2rem(18px);
                font-family: Arial;
            }
        }
    }
    &-swiper {
        margin-bottom: px2rem(30px);
        .swiper-slide {
            width: px2rem(210px);
            position: relative;
            cursor: pointer;
            .dot {
                width: px2rem(142px);
                height: px2rem(142px);
                margin: 0 auto;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: px2rem(12px);
                position: relative;
            }
            .bg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                box-sizing: border-box;
                border: px2rem(5px) solid #fff;
                background: linear-gradient(45deg, #4e1d6d, #aa57b6);
                border-radius: 100%;
                opacity: 0;
            }
            .num {
                display: flex;
                flex: 1;
                box-sizing: border-box;
                border: 1px dashed #fff;
                text-align: center;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                font-size: px2rem(24px);
                color: #fff;
                font-family: Arial;
                position: relative;
                z-index: 2;
                border-radius: 100%;
                width: 100%;
                height: 100%;
            }
            &.on {
                .bg {
                    opacity: 1;
                }
            }
            + .swiper-slide:before {
                position: absolute;
                width: px2rem(32px);
                height: px2rem(5px);
                background-image: url(../images/special_dot_1.png);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                top: 50%;
                margin-top: px2rem(-2px);
                left: px2rem(-16px);
                z-index: 5;
                content: '';
            }
        }
    }
    .tips {
        text-align: center;
        color: #fff;
        font-size: px2rem(18px);
    }
    .bottom-box {
        padding: px2rem(30px) px2rem(30px) px2rem(70px);
        background-color: #fff;
    }
    &-con {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: px2rem(228px) px2rem(75px) px2rem(55px);
        .events-bd {
            .box {
                display: none;
                &:first-child {
                    display: block;
                }
            }
            .img {
                border-radius: px2rem(18px);
                overflow: hidden;
                border: 2px solid #fff;
                margin-bottom: px2rem(10px);
                display: block;
                span {
                    display: block;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                    top: 0;
                    left: 0;
                    padding-top: percentage(301/526);
                }
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }
            .title {
                font-size: px2rem(26px);
                color: #fff;
                text-align: center;
                display: block;
            }
        }
    }
}
.special-active {
    padding: px2rem(40px) px2rem(30px) px2rem(60px);
    background-color: #f5f5f5;
    .title {
        text-align: center;
        margin-bottom: px2rem(20px);
        .cn {
            font-size: px2rem(36px);
            color: #c11545;
            font-weight: bold;
        }
        .en {
            font-family: Arial;
            font-size: px2rem(18px);
            color: #666;
        }
    }
    &-swiper {
        .swiper-slide {
            .wp {
                display: block;
                background-color: #fff;
                padding: px2rem(30px) px2rem(30px) px2rem(40px);
                background-image: url(../images/special_bg3.jpg);
                background-position: right bottom;
                background-repeat: no-repeat;
                background-size: px2rem(83px) auto;
                width: 100%;
                box-sizing: border-box;
            }
            .img {
                position: relative;
                width: 100%;
                padding-top: percentage(424/632);
                overflow: hidden;
                margin-bottom: px2rem(15px);
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }
            .t {
                font-size: px2rem(28px);
                font-weight: bold;
                color: #333;
                margin-bottom: px2rem(10px);
            }
            .p {
                font-size: px2rem(26px);
                line-height: px2rem(38px);
                color: #888;
                margin-bottom: px2rem(10px);
            }
            .arrow {
                border: 2px solid #e0594c;
                color: #e0594c;
                width: px2rem(40px);
                height: px2rem(40px);
                text-align: center;
                line-height: px2rem(40px);
                border-radius: 100%;
                font-size: px2rem(20px);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .swiper-pagination {
            position: static;
            margin-top: px2rem(20px);
            &-bullet {
                background-color: #fff;
                opacity: 1;
                &-active {
                    background: #e0594c;
                }
            }
        }
    }
}
.special-publicity {
    padding: px2rem(60px) px2rem(30px) px2rem(80px);
    background-image: url(../images/special_bg4.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .title {
        text-align: center;
        color: #fff;
        margin-bottom: px2rem(20px);
        .cn {
            font-size: px2rem(36px);
            font-weight: bold;
        }
        .en {
            font-family: Arial;
            font-size: px2rem(18px);
        }
    }
    .topic {
        display: block;
        padding: px2rem(30px);
        background-color: #fff;
        margin-bottom: px2rem(20px);
        .img {
            position: relative;
            width: 100%;
            padding-top: percentage(345/629);
            overflow: hidden;
            margin-bottom: px2rem(20px);
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .info {
            padding: 0 px2rem(15px);
        }
        .t {
            font-size: px2rem(28px);
            font-weight: bold;
            color: #333;
            margin-bottom: px2rem(10px);
        }
        .p {
            font-size: px2rem(26px);
            line-height: px2rem(38px);
            color: #666;
            margin-bottom: px2rem(10px);
        }
        .arrow {
            border: 2px solid #e0594c;
            color: #e0594c;
            width: px2rem(40px);
            height: px2rem(40px);
            text-align: center;
            line-height: px2rem(40px);
            border-radius: 100%;
            font-size: px2rem(20px);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &-swiper {
        .wp {
            display: block;
            background-color: #fff;
            padding: px2rem(20px);
            width: 100%;
            box-sizing: border-box;
        }
        .img {
            width: 100%;
            position: relative;
            padding-top: percentage(163/302);
            overflow: hidden;
            margin-bottom: px2rem(20px);
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
        .t {
            font-size: px2rem(26px);
            line-height: px2rem(36px);
            height: px2rem(72px);
            overflow: hidden;
            color: #333;
        }
        .swiper-pagination {
            position: static;
            padding-top: px2rem(50px);
            &-bullet {
                opacity: 1;
                background: #fff;
                &-active {
                    background: #e15d50;
                }
            }
        }
    }
}
.special-video {
    padding: px2rem(50px) 0 px2rem(105px);
    position: relative;
    overflow: hidden;
    background: #f5f5f5;
    .title {
        text-align: center;
        margin-bottom: px2rem(20px);
        position: relative;
        z-index: 5;
        .cn {
            font-size: px2rem(36px);
            font-weight: bold;
            color: #c11545;
        }
        .en {
            font-family: Arial;
            font-size: px2rem(18px);
            color: #666;
        }
    }
    &:after {
        position: absolute;
        left: px2rem(30px);
        right: px2rem(30px);
        bottom: px2rem(68px);
        height: px2rem(384px);
        border: px2rem(8px) solid #e0594c;
        box-sizing: border-box;
        z-index: 1;
        content: '';
        background: #fff;
    }
    &-con {
        margin: 0 px2rem(50px);
        .video-box {
            position: relative;
            width: 100%;
            padding-top: percentage(436/648);
            overflow: hidden;
            z-index: 5;
            background-color: #fff;
            margin-bottom: px2rem(20px);
            video,
            .video-js {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .video-js .vjs-big-play-button {
                right: px2rem(20px);
                top: auto;
                bottom: px2rem(20px);
                left: auto;
            }
        }
        .video-swiper {
            position: relative;
            z-index: 5;
            .swiper-slide {
                position: relative;
                cursor: pointer;
                .img {
                    width: 100%;
                    padding-top: percentage(214/316);
                    overflow: hidden;
                    position: relative;
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    &:after {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: linear-gradient(to bottom, transparent, rgba(#000, .8));
                        content: '';
                        z-index: 2;
                    }
                }
                .p {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    font-size: px2rem(26px);
                    font-weight: bold;
                    color: #fff;
                    text-align: center;
                    bottom: px2rem(15px);
                    z-index: 2;
                }
            }
            .swiper-pagination {
                position: static;
                padding-top: px2rem(20px);
                &-bullet {
                    opacity: 1;
                    background: #f5f5f5;
                    &-active {
                        background: #e15d50;
                    }
                }
            }
        }
    }
}