.product-banner {
    padding-bottom: (810/1920)*100%;
}
.prompt {
    padding: px2rem(40px) px2rem(20px) px2rem(80px);
    background: url(../images/sub_bg.jpg) no-repeat bottom left;
    background-size: 100%;
    &-article {
        position: relative;
        background: #fff;
        padding: px2rem(70px) px2rem(20px) px2rem(100px);
        box-sizing: border-box;
        color: #556;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: px2rem(40px);
        &.pd20 {
            padding: px2rem(40px) px2rem(20px);
        }
        &-title {
            text-align: center;
            border-bottom: 1px dashed #ccc;
            padding-bottom: 15px;
            .title {
                margin-bottom: 10px;
                font-size: 18px;
            }
            .time {
                color: #666;
                font-size: 12px;
            }
        }
        p {
            margin-bottom: 10px;
        }
        img {
            max-width: 100%;
            height: auto !important;
        }
        .img {
            margin-bottom: px2rem(30px);
            text-align: center;
            img {
                max-width: 70%;
            }
        }
        .video {
            position: relative;
            display: block;
            width: 100%;
            padding-bottom: (349/625)*100%;
            overflow: hidden;
            .video-box {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                &:hover {
                    .vjs-big-play-button {
                        background: rgba(0, 0, 0, .2);
                    }
                }
            }
            .video-js {
                width: 100%;
                position: relative;
                height: 100%;
                .vjs-big-play-button {
                    position: absolute;
                    top: auto;
                    left: auto;
                    bottom: px2rem(20px);
                    right: px2rem(30px);
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                    font-size: 22px;
                    background: none;
                }
            }
        }
        .back-home {
            display: block;
            text-align: center;
            margin: px2rem(20px) auto 0;
            width: px2rem(260px);
            height: px2rem(60px);
            font-size: px2rem(28px);
            line-height: px2rem(60px);
            border-radius: px2rem(30px);
            color: #555;
            background-color: #ebeced;
        }
    }
}
.album-box {
    width: 100%;
    font-size: 0px;
    .li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        padding: 0 px2rem(10px);
        box-sizing: border-box;
        @media screen and (max-width: 350px) {
            width: 50%;
        }
        a {
            margin-bottom: px2rem(20px);
            border: 1px solid #e1e1e1;
            box-sizing: border-box;
            width: 100%;
            display: block;
            transition: all .3s ease;
            &:hover {
                transform: translate(0, -5px);
                box-shadow: 0 2px 18px rgba(0, 0, 0, .1);
            }
            .img {
                position: relative;
                width: 100%;
                padding-bottom: (332/236)*100%;
                img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                position: relative;
                font-size: 12px;
                color: #555;
                background: #f7f7f7;
                height: 40px;
                padding-top: 6px;
                box-sizing: border-box;
                text-align: center;
                overflow: hidden;
                line-height: 16px;
                p,
                h2 {
                    font-size: 12px;
                    line-height: 40px;
                    max-height: 40px;
                    overflow: hidden;
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
    }
}
.report-box {
    margin-top: px2rem(20px);
    font-size: 0px;
    &.achievement-box {
        .li {
            a .img {
                padding-bottom: (323/430)*100%;
            }
            a .text {
                height: 50px;
            }
        }
    }
    .li {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 0 px2rem(10px);
        box-sizing: border-box;
        a {
            margin-bottom: px2rem(20px);
            border: 2px solid #fff;
            box-sizing: border-box;
            width: 100%;
            display: block;
            transition: all .3s ease;
            &:hover {
                transform: translate(0, -5px);
                box-shadow: 0 2px 18px rgba(0, 0, 0, .1);
            }
            .img {
                position: relative;
                width: 100%;
                padding-bottom: (432/320)*100%;
                img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                position: relative;
                font-size: 14px;
                color: #555;
                background: #fff;
                height: 40px;
                text-align: center;
                overflow: hidden;
                line-height: 20px;
                p,
                h2 {
                    max-height: 40px;
                    overflow: hidden;
                    position: relative;
                    top: 50%;
                    font-size: 14px;
                    line-height: 20px;
                    transform: translate(0, -50%);
                }
            }
        }
    }
}
.news-list {
    margin: px2rem(40px) 0px;
    .li {
        margin-bottom: px2rem(14px);
        a {
            padding: px2rem(20px);
            background: #fff;
            display: block;
            .img {
                float: left;
                width: 140px;
                height: 96px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                padding-left: px2rem(30px);
                overflow: hidden;
                .title {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 6px;
                    color: #777;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .p {
                    line-height: 20px;
                    font-size: 14px;
                    color: #aaa;
                    overflow: hidden;
                    max-height: 40px;
                    margin-bottom: 4px;
                }
                .time {
                    color: #ccc;
                    font-size: 10px;
                    span {
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    &.life-list {
        .li a .text {
            padding-top: px2rem(24px);
        }
        .li a .title {
            margin-bottom: 10px;
        }
        .li a .p {
            color: #777;
        }
    }
}
.notice-list {
    margin: px2rem(40px) 0px;
    a, .a {
        box-sizing: border-box;
        width: 100%;
        padding: 0 px2rem(30px);
        display: block;
        background: #fff;
        line-height: 52px;
        &:nth-child(even) {
            background: #fafafa;
        }
        .show {
            display: none;
        }
        .wrapper {
            width: 100%;
            font-size: 14px;
            color: #666;
            line-height: 52px;
            display: flex;
            align-items: center;
            position: relative;
            box-sizing: border-box;
            padding-left: 24px;
            &:before {
                position: absolute;
                left: 2px;
                top: 50%;
                transform: translateY(-50%);
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $red;
                display: block;
            }
        }
        .timer {
            margin-right: 6px;
        }
        .text {
            font-size: 14px;
            color: #666;
            line-height: 52px;
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 52px;
        }
    }
}
.store {
    margin-top: px2rem(50px);
    a {
        padding: 0 60px 0 30px;
        position: relative;
        display: block;
        margin-bottom: px2rem(20px);
        background: #fff;
        height: 60px;
        line-height: 60px;
        color: #666;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:after {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 20px;
            content: "";
            background: url(../images/store_icon_1.png);
            width: 30px;
            height: 30px;
            background-size: 100% 100%;
        }
    }
}
.job {
    margin-top: px2rem(50px);
    &-text {
        color: #777;
        font-size: 14px;
        line-height: 24px;
        img {
            max-width: 100%;
            height: auto !important;
        }
    }
    &-img {
        margin-top: px2rem(30px);
        font-size: 0px;
        .li {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            padding: 0 px2rem(10px);
            box-sizing: border-box;
            &.on {
                .text .icon {
                    display: inline-block;
                }
            }
            .text {
                text-align: center;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translate(0, -50%);
                font-size: 16px;
                color: #fff;
                .icon {
                    width: 30px;
                    display: none;
                    vertical-align: middle;
                    img {
                        width: 100%;
                    }
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            .img-box {
                display: block;
                border-radius: 4px;
                overflow: hidden;
                position: relative;
                width: 100%;
                padding-bottom: (310/600)*100%;
                .img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    &-form {
        margin-top: px2rem(20px);
        margin-bottom: px2rem(36px);
        font-size: 0px;
        .li {
            display: inline-block;
            vertical-align: top;
            height: 40px;
            width: 50%;
            padding: 0 px2rem(10px);
            box-sizing: border-box;
        }
        .form-box {
            border: 1px solid #c0c0c0;
            padding-left: px2rem(20px);
            box-sizing: border-box;
            display: flex;
            position: relative;
            width: 100%;
            width: 100%;
            .text {
                line-height: 40px;
                font-size: 14px;
                color: #666;
            }
            .input-box {
                position: relative;
                z-index: 4;
                flex: 1;
                height: 100%;
                input {
                    width: 100%;
                    height: 40px;
                    background: none;
                    border: 0px;
                    font-size: 14px;
                    line-height: 40px;
                    color: #666;
                }
                select {
                    width: 100%;
                    height: 100%;
                    background: none;
                    border: 0px;
                    color: #666;
                    font-size: 14px;
                    line-height: 40px;
                    position: relative;
                }
            }
            .btn {
                cursor: pointer;
                font-size: 18px;
                line-height: 38px;
                width: 38px;
                text-align: center;
                color: #80848f;
                background: none;
                border: 0px;
            }
            .select-box {
                padding-right: 32px;
                &:after {
                    content: "";
                    z-index: -1;
                    position: absolute;
                    right: 14px;
                    top: 50%;
                    transform: translate(0, -50%);
                    content: "";
                    width: 10px;
                    height: 6px;
                    background: url(../images/job_icon_3.png);
                    background-size: 100% 100%;
                }
            }
        }
    }
    .talent-recruitment {
        margin-bottom: px2rem(40px);
        .talent-recruitment-title {
            position: relative;
            width: 100%;
            background: #9a9a9a;
            height: 53px;
            line-height: 53px;
            font-size: 0;
            margin-bottom: 10px;
            font-size: 0px;
            li {
                box-sizing: border-box;
                padding-left: px2rem(70px);
                width: 33.33%;
                font-size: 14px;
                color: #fafafa;
                display: inline-block;
                vertical-align: middle;
                &:last-child {
                    padding-left: px2rem(40px);
                }
            }
        }
        .talent-recruitment-list {
            > li {
                position: relative;
                width: 100%;
                background: #fff;
                height: 53px;
                border: 1px solid #d7d7d7;
                line-height: 53px;
                font-size: 0;
                margin-bottom: 10px;
                a {
                    display: block;
                }
                .talent-title {
                    position: relative;
                    height: 53px;
                    overflow: hidden;
                    li {
                        box-sizing: border-box;
                        padding-left: px2rem(20px);
                        width: 33.33%;
                        font-size: 14px;
                        display: inline-block;
                        vertical-align: top;
                        color: #333;
                        &:nth-child(3) {
                            padding-left: px2rem(40px);
                            padding-right: 60px;
                        }
                    }
                    .icon {
                        position: absolute;
                        right: px2rem(20px);
                        top: 50%;
                        transform: translate(0, -50%);
                        width: 30px;
                        height: 30px;
                        padding-left: 0;
                        border-radius: 50%;
                        border: 1px solid #e6e6e6;
                        line-height: 30px;
                        text-align: center;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
.job-info-article {
    margin-top: px2rem(40px);
    position: relative;
    background: #fff;
    padding: px2rem(70px) px2rem(20px);
    box-sizing: border-box;
    color: #999;
    font-size: 14px;
    line-height: 26px;
    .button {
        margin-top: 12px;
        border-radius: 16px;
        padding: 0 12px;
        border: 1px solid #ccc;
        font-size: 14px;
        color: #000;
        line-height: 26px;
        display: inline-block;
    }
    h5 {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
        color: #333;
        img {
            width: 28px;
        }
        img, span {
            vertical-align: middle;
        }
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    .hr {
        margin: px2rem(30px) 0px;
        width: 100%;
        height: 1px;
        background: #999;
    }
}
.equity {
    padding: px2rem(20px);
    padding-bottom: px2rem(60px);
    background: #f9f9f9;
    &-top {
        color: #fff;
        background: $red;
        padding: px2rem(40px) px2rem(30px);
        .name {
            font-size: 14px;
            padding-bottom: px2rem(20px);
        }
        .num {
            h3 {
                display: inline-block;
                font-size: 30px;
                font-weight: bold;
            }
            .up {
                vertical-align: top;
                display: inline-block;
                background-image: url(../images/relation-san.png);
                margin-top: 8px;
                margin-left: 7px;
                width: 12px;
                height: 11px;
            }
            .down {
                vertical-align: top;
                display: inline-block;
                width: 13px;
                height: 8px;
                background-image: url(../images/san-icon.png);
                margin-top: 8px;
                margin-left: 7px;
            }
        }
        .num2 {
            padding-top: 4px;
            font-size: 14px;
        }
        .p {
            padding-top: 4px;
            font-size: 14px;
            line-height: 24px;
            color: rgba(255, 255, 255, .5);
        }
    }
    &-li {
        padding: px2rem(30px) px2rem(30px);
        background: #fff;
        font-size: 14px;
        color: #999;
        h4 {
            font-size: 16px;
            font-weight: bold;
            color: #666;
            margin-bottom: 6px;
        }
        + .equity-li {
            border-top: 1px solid #ebebeb;
        }
    }
    &-img {
        margin-top: px2rem(20px);
        background: #fff;
        padding: px2rem(20px);
        img {
            max-width: 100%;
            height: auto !important;
        }
    }
    &-btn2 {
        text-align: center;
    }
    &-btn {
        margin-top: px2rem(40px);
        display: inline-block;
        background: #f0f0f0;
        line-height: 30px;
        padding: 0 14px;
        font-size: 14px;
        color: #666;
        border-radius: 15px;
        img, span {
            vertical-align: middle;
        }
    }
}
.equity-con {
    padding: px2rem(60px) px2rem(20px) px2rem(100px);
}
.equity-title {
    text-align: center;
    color: $red;
    font-size: px2rem(32px);
    font-weight: bold;
    margin-bottom: px2rem(25px);
}
.equity-table-con {
    display: flex;
    border: 1px solid #ebebeb;
    overflow: hidden;
    .thread {
        background-color: #6c6d6f;
        width: px2rem(80px);
        .th {
            border-bottom: 1px solid #ebebeb;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: px2rem(24px);
            color: #fff;
            line-height: px2rem(35px);
            text-align: center;
            padding-right: px2rem(5px);
            padding-left: px2rem(5px);
            box-sizing: border-box;
            height: px2rem(180px);
            &:last-child {
                border-bottom: none;
            }
            //&:nth-child(1),
            //&:nth-child(3),
            //&:nth-child(6) {
            //    height: px2rem(160px);
            //}
            //&:nth-child(2),
            //&:nth-child(4) {
            //    height: px2rem(200px);
            //}
            //&:nth-child(5) {
            //    height: px2rem(190px);
            //}
        }
    }
    .info {
        flex: 1;
        overflow: hidden;
    }
}
.equity-swiper {
    .swiper-slide {
        width: px2rem(210px);
        box-sizing: border-box;
        .td {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            font-size: px2rem(24px);
            color: #888;
            line-height: px2rem(35px);
            border-right: 1px solid #ebebeb;
            border-bottom: 1px solid #ebebeb;
            text-align: center;
            align-items: center;
            justify-content: center;
            height: px2rem(180px);
            &:last-child {
                border-bottom: none;
            }
            //&:nth-child(1),
            //&:nth-child(3),
            //&:nth-child(6) {
            //    height: px2rem(160px);
            //}
            //&:nth-child(2),
            //&:nth-child(4) {
            //    height: px2rem(200px);
            //}
            //&:nth-child(5) {
            //    height: px2rem(190px);
            //}
        }
    }
}
.equity-tool {
    display: flex;
    align-items: center;
    margin-top: px2rem(30px);
    .equity-table-prev,
    .equity-table-next {
        width: px2rem(40px);
        height: px2rem(40px);
        line-height: px2rem(40px);
        color: #000;
        font-size: px2rem(24px);
        text-align: center;
    }
    .equity-table-prev {
        transform: rotate(180deg);
    }
    .equity-scroll-bar {
        flex: 1;
        overflow: hidden;
        height: px2rem(10px);
        background: #eee;
        border-radius: px2rem(5px);
        .swiper-scrollbar-drag {
            background: #888;
        }
    }
}
.video {
    &-top {
        position: relative;
        margin: 0 px2rem(-20px);
        margin-top: px2rem(40px);
        padding: px2rem(80px) px2rem(40px);
        background: #fff none no-repeat center center;
        background-size: cover;
        background-image: url(../images/video_bg.jpg);
        .video-iframe {
            position: relative;
            width: 100%;
            padding-bottom: (349/625)*100%;
            overflow: hidden;
            border-radius: 8px;
        }
        .video {
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            overflow: hidden;
            .video-box {
                width: 100%;
                height: 100%;
                max-width: 100%;
                min-width: 100%;
                &:hover {
                    .vjs-big-play-button {
                        background: rgba(0, 0, 0, .2);
                    }
                }
            }
            .video-js {
                width: 100%;
                position: relative;
                height: 100%;
                .vjs-big-play-button {
                    position: absolute;
                    top: auto;
                    left: auto;
                    bottom: px2rem(20px);
                    right: px2rem(30px);
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                    font-size: 22px;
                    background: none;
                }
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
                &:after {
                    opacity: .8;
                }
            }
        }
    }
    &-nav {
        padding-top: px2rem(10px);
        padding-bottom: px2rem(40px);
        text-align: center;
        div,
        .tit {
            padding: 0 8px;
            display: inline-block;
            font-size: 14px;
            color: #333;
            font-weight: bold;
            &.on {
                color: $red;
            }
            span,
            img {
                vertical-align: middle;
            }
            span {
                font-size: 14px;
            }
            img {
                width: 22px;
            }
            + div,
            + .tit {
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    transform: translate(0, -50%);
                    width: 1px;
                    height: 14px;
                    background: #d8d8d8;
                }
            }
        }
    }
    &-list {
        display: none;
        font-size: 0px;
        &.on {
            display: block;
        }
        .li {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            box-sizing: border-box;
            padding: 0 px2rem(10px);
            margin-bottom: px2rem(10px);
            a {
                position: relative;
                display: block;
                width: 100%;
                padding-bottom: (243/341)*100%;
                .img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .text {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 100%;
                    background: rgba(0, 0, 0, 0.3);
                    color: #fff;
                    font-size: px2rem(24px);
                    padding: px2rem(20px) 10px;
                    padding-right: 60px;
                    box-sizing: border-box;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        right: px2rem(20px);
                        width: px2rem(40px);
                        height: px2rem(40px);
                        background: url(../images/player.png);
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}
.nav-win {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    z-index: 10000;
    .win {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 4px 51px rgba(2, 2, 2, .41);
        width: 100%;
        box-sizing: border-box;
        padding: px2rem(110px) px2rem(50px) px2rem(40px);
        background: #fff;
        .close {
            position: absolute;
            right: 0px;
            top: 0px;
            width: px2rem(80px);
            img {
                width: 100%;
            }
        }
        .box {
            font-size: 0px;
            a {
                width: 33.33%;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                font-size: 14px;
                color: #666;
                padding: 0 px2rem(10px);
                box-sizing: border-box;
                margin-bottom: px2rem(50px);
                img {
                    margin-bottom: 6px;
                    width: 20px;
                }
            }
        }
    }
}
.honor-win {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    z-index: 10000;
    .win {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 4px 51px rgba(2, 2, 2, .41);
        border: solid 8px rgba(2, 2, 2, .36);
        width: 90%;
        box-sizing: border-box;
        padding: px2rem(110px) px2rem(70px) px2rem(40px);
        background: #fff;
        .close {
            position: absolute;
            right: 0px;
            top: 0px;
            width: px2rem(80px);
            img {
                width: 100%;
            }
        }
        .img {
            position: relative;
            width: 100%;
            padding-bottom: 80%;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }
        .text {
            padding-top: px2rem(30px);
            h5 {
                font-size: 14px;
                color: #333;
                padding-bottom: px2rem(30px);
            }
            .article {
                border-top: 1px dashed #ddd;
                padding-top: px2rem(30px);
                font-size: 12px;
                line-height: 22px;
                color: #666;
            }
        }
    }
    &.overall-cabinet-win {
        .win {
            border: 0px;
        }
        .text {
            h5 {
                padding-bottom: px2rem(10px);
            }
            .article {
                padding-top: px2rem(10px);
                border-top: 0px;
            }
        }
    }
}
.history {
    padding: 0 px2rem(20px);
    &-li {
        display: none;
        &.on {
            display: block;
        }
    }
    &-img {
        margin-top: px2rem(30px);
        border-radius: 4px;
        position: relative;
        width: 100%;
        padding-bottom: (380/665)*100%;
        background: #fff none no-repeat center center;
        background-size: cover;
        .year {
            position: absolute;
            bottom: px2rem(20px);
            left: px2rem(20px);
            font-size: 18px;
            color: #fff;
        }
    }
    &-text {
        margin-top: px2rem(30px);
        font-size: 14px;
        color: #333;
        line-height: 22px;
    }
}
.consult {
    &-top {
        width: 100%;
        padding: px2rem(50px) px2rem(20px) px2rem(30px);
        background: #fff;
        box-sizing: border-box;
        .text {
            margin-bottom: px2rem(40px);
            padding-left: px2rem(20px);
            .iconfont {
                margin-right: px2rem(20px);
                float: left;
                font-size: 24px;
            }
            color: #777;
            font-size: 14px;
            line-height: 20px;
            .title {
                margin-bottom: px2rem(20px);
                color: #333;
                font-size: 16px;
            }
            &-right {
                overflow: hidden;
            }
        }
        .map {
            width: 100%;
            padding-bottom: (310/710)*100%;
            position: relative;
            &-box {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
    }
    &-form {
        margin-top: px2rem(20px);
        padding: px2rem(30px) px2rem(20px);
        background: #fff;
        .title {
            font-size: 16px;
            color: #333;
            margin-bottom: px2rem(20px);
        }
        .form {
            font-size: 0px;
            margin: 0 px2rem(-10px);
        }
        .consult-button {
            margin-top: px2rem(20px);
            color: #333;
            font-size: 0;
            text-align: center;
            input {
                display: inline-block;
                vertical-align: top;
                line-height: 34px;
                padding: 0 px2rem(40px);
                background: none;
                font-size: 12px;
                border: 1px solid #e5e5e5;
            }
            input[type='reset'] {
                background: #fcfcfc;
            }
            input + input {
                border-left: 0;
            }
        }
        .input-box {
            margin-bottom: px2rem(10px);
            width: 50%;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            padding: 0 px2rem(5px);
            &.input100 {
                width: 100%;
                .input {
                    padding-left: 9em;
                }
            }
            .input {
                background: #f8f8f8;
                height: 40px;
                line-height: 40px;
                position: relative;
                padding: 0 px2rem(10px);
                box-sizing: border-box;
                padding-left: 7em;
                font-size: 12px;
                .text {
                    position: absolute;
                    top: 0px;
                    left: px2rem(30px);
                    color: #666;
                    font-size: 12px;
                    span {
                        position: absolute;
                        left: px2rem(-16px);
                        color: $red;
                    }
                }
                input {
                    color: #666;
                    font-size: 12px;
                    background: none;
                    border: 0px;
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .ver-box {
            display: flex;
            .box {
                flex: 1;
                background: #f8f8f8;
                height: 40px;
                line-height: 40px;
                display: flex;
            }
            .text {
                font-size: 12px;
                color: #666;
                padding-left: px2rem(30px);
            }
            .input {
                flex: 1;
                input {
                    display: block;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 12px;
                    color: #666;
                    background: none;
                    border: none;
                    appearance: none;
                }
            }
        }
        .ver-img {
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            width: px2rem(200px);
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
.business {
    &-title {
        margin-bottom: 14px;
        text-align: center;
        font-size: 20px;
        color: #333;
        span {
            display: inline-block;
            background: url(../images/index_icon_1.png) no-repeat;
            background-size: 40px;
            padding-bottom: 14px;
            padding-left: 34px;
        }
    }
    &-swiper {
        overflow: hidden;
        .business-pagination {
            padding: 4px 0px 16px;
            text-align: center;
            span {
                background: #333;
                &.swiper-pagination-bullet-active {
                    background: $red;
                }
            }
        }
    }
    &-list {
        font-size: 0px;
        .li {
            margin-bottom: px2rem(30px);
            width: 50%;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            padding: 0 px2rem(10px);
            a {
                border-radius: 4px;
                overflow: hidden;
                display: block;
                width: 100%;
                .img {
                    width: 100%;
                    padding-bottom: (273/276)*100%;
                    background: #fff none no-repeat center center;
                    background-size: cover;
                    position: relative;
                    overflow: hidden;
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        display: block;
                    }
                }
                .text {
                    background: #fff;
                    font-size: 14px;
                    text-align: center;
                    line-height: 40px;
                    p {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    img {
                        width: 20px;
                        vertical-align: middle;
                    }
                    span {
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
.team {
    &-text {
        color: #777;
        font-size: 14px;
        line-height: 24px;
        img {
            max-width: 100%;
            height: auto !important;
        }
    }
    &-list {
        font-size: 0px;
        margin: 0 px2rem(-10px);
        margin-top: px2rem(40px);
        .li {
            margin-bottom: px2rem(20px);
            padding: 0 px2rem(10px);
            width: 50%;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            .li-info {
                width: 100%;
                background: #fff;
                border-radius: 4px;
                padding: px2rem(30px);
                box-sizing: border-box;
                .user {
                    margin-bottom: px2rem(20px);
                }
                .iconfont {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background: #f5f5f5;
                    text-align: center;
                    line-height: 24px;
                    font-size: 12px;
                    color: #333;
                    float: left;
                    margin-right: px2rem(20px);
                }
                .user-name {
                    overflow: hidden;
                    font-size: 12px;
                    padding-top: 4px;
                    color: #000;
                    line-height: 18px;
                }
                .article {
                    font-size: 14px;
                    color: #939393;
                    line-height: 24px;
                    height: 24*5px;
                    overflow: hidden;
                }
            }
        }
    }
}
.nav-menu {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    background: $red;
    z-index: 100;
    text-align: center;
    img {
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        width: 20px;
    }
}
.product-bottom-swiper {
    overflow: hidden;
}
.product-bottom-pagination {
    text-align: center;
    margin: px2rem(20px) 0px;
    span {
        margin: 0 4px;
        background: #333;
        &.swiper-pagination-bullet-active {
            background: $red;
        }
    }
}
.overall-cabinet {
    &-swiper-box {
        position: relative;
        overflow: hidden;
        &-pn {
            top: 0px;
            left: 0px;
            width: 100%;
            position: absolute;
            padding-bottom: (430/710)*100%;
        }
        &-prev {
            position: absolute;
            z-index: 10;
            top: 50%;
            left: px2rem(20px);
            transform: translate(0, -50%);
            width: 40px;
            height: 40px;
            background: url(../images/product_arrow_1.png);
            background-size: 100% 100%;
            border-radius: 50%;
        }
        &-next {
            position: absolute;
            z-index: 10;
            top: 50%;
            right: px2rem(20px);
            transform: translate(0, -50%) rotate(180deg);
            width: 40px;
            height: 40px;
            background: url(../images/product_arrow_1.png);
            background-size: 100% 100%;
            border-radius: 50%;
        }
    }
    &-bottom {
        padding: px2rem(46px) px2rem(20px) px2rem(60px);
        box-sizing: border-box;
        background: #fff;
        .top {
            text-align: center;
            margin-bottom: px2rem(24px);
            .t {
                display: inline-block;
                background: url(../images/index_icon_1.png) no-repeat;
                background-size: 40px;
                padding-bottom: 14px;
                padding-left: 34px;
                font-size: 20px;
            }
        }
        .overall-cabinet-box {
            overflow: hidden;
            display: none;
            &.on {
                display: block;
            }
            font-size: 0;
            //padding: 0 px2rem(10px);
            .product-pagination {
                margin-top: 10px;
                text-align: center;
                span {
                    background: #666;
                    &.swiper-pagination-bullet-active {
                        background: $red;
                    }
                }
            }
            .li {
                margin-bottom: px2rem(10px);
                box-sizing: border-box;
                width: 50%;
                display: inline-block;
                vertical-align: top;
                &:nth-child(2n+1) {
                    padding-right: px2rem(5px);
                }
                &:nth-child(2n) {
                    padding-left: px2rem(5px);
                }
                .show {
                    display: none;
                }
                a {
                    display: block;
                    width: 100%;
                    position: relative;
                    padding-bottom: (233/354)*100%;
                    .img {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        padding: 0 px2rem(20px);
                        box-sizing: border-box;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, .4);
                        &-box {
                            position: relative;
                            top: 50%;
                            transform: translate(0, -50%);
                            text-align: center;
                            p, h2 {
                                overflow: hidden;
                                height: 32px;
                                line-height: 16px;
                                font-size: 14px;
                                color: #fff;
                                margin-bottom: 6px;
                            }
                            img {
                                width: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    &-top {
        display: block;
        background: #f0f0f0;
        .img {
            position: relative;
            width: 100%;
            padding-bottom: (430/710)*100%;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                object-fit: cover;
            }
        }
        .text {
            padding: px2rem(30px);
            padding-bottom: px2rem(50px);
            font-size: 14px;
            line-height: 24px;
            color: #666;
            .title {
                margin-bottom: 4px;
                font-size: 14px;
                color: #333;
                font-weight: bold;
            }
            .more {
                margin-top: px2rem(20px);
                display: inline-block;
                font-size: 12px;
                line-height: 24px;
                padding: 0 8px;
                border: 1px solid #999;
            }
        }
    }
}
.store2-form {
    margin-top: px2rem(20px);
    font-size: 0px;
    .btn {
        background: $red;
        border: 0px;
        color: #fff;
        font-size: 14px;
        line-height: 35px;
        padding: 0 px2rem(50px);
        i {
            font-size: 12px;
        }
        span {
            padding-left: 4px;
        }
    }
    .input {
        margin-bottom: px2rem(10px);
        position: relative;
        height: 45px;
        background: #fff;
        width: 25%;
        display: inline-block;
        vertical-align: top;
        &.input100 {
            width: 100%;
        }
        &.select:after {
            position: absolute;
            right: px2rem(20px);
            top: 50%;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            content: "";
            width: 0px;
            height: 0px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 6px solid #b6b6b6;
        }
        input {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 0 px2rem(20px);
            font-size: 12px;
            border: 0px;
            color: #888;
        }
        select {
            font-size: 12px;
            box-sizing: border-box;
            padding: 0 px2rem(20px);
            position: relative;
            z-index: 100;
            color: #888;
            background: none;
            width: 100%;
            height: 100%;
            border: none;
        }
    }
}
.store2-list {
    margin: px2rem(30px) 0px;
    .li {
        &:nth-child(n + 9) {
            display: none;
            &.on {
                display: block;
            }
        }
        + .li {
            border-top: 1px solid #ebebeb;
        }
        a {
            padding: px2rem(30px);
            display: block;
            background: #fff;
            .iconfont {
                margin-right: px2rem(10px);
                float: left;
                font-size: 20px;
                color: #999;
            }
            .text {
                overflow: hidden;
                .title {
                    margin-bottom: 4px;
                    font-size: 16px;
                }
                .article {
                    line-height: 24px;
                    font-size: 14px;
                    color: #999;
                }
            }
        }
    }
}
.govemance-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: px2rem(30px);
}
.govemance-table {
    width: 100%;
    border: 1px solid #ddd;
    .tr {
        border-bottom: 1px solid #ddd;
        width: 100%;
        background: #fff;
        display: table;
        font-size: 14px;
        color: #777;
        line-height: 24px;
        &:last-child {
            border-bottom: 0px;
        }
        &:nth-child(even) {
            background: #f9f9f9;
        }
        .left {
            display: table-cell;
            width: 40%;
            padding: px2rem(20px);
            box-sizing: border-box;
        }
        .right {
            border-left: 1px solid #ddd;
            display: table-cell;
            padding: px2rem(40px) px2rem(20px);
            box-sizing: border-box;
            .table {
                font-size: 0px;
            }
            .see {
                position: relative;
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                line-height: 36px;
                color: #999;
                padding: px2rem(10px);
                &:hover {
                    color: $red;
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
                &:before {
                    font-size: 22px;
                    line-height: 36px;
                    vertical-align: middle;
                    padding-right: 6px;
                }
            }
            .down {
                display: inline-block;
                vertical-align: top;
                position: relative;
                font-size: 12px;
                line-height: 36px;
                color: #999;
                padding: px2rem(10px);
                &:hover {
                    color: $red;
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
                &:after {
                    content: "";
                    width: 1px;
                    height: 16px;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 0px;
                    background: #999;
                }
                &:before {
                    font-size: 22px;
                    line-height: 36px;
                    vertical-align: middle;
                    padding-right: 6px;
                }
            }
            .text33 {
                padding-right: 10px;
                box-sizing: border-box;
                font-size: 14px;
                vertical-align: top;
                display: inline-block;
            }
        }
    }
}
.li-a {
    position: relative;
    display: none;
    &.on {
        display: block;
    }
    .img {
        width: 100%;
        padding-bottom: 50.45045%;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }
    .title {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .4);
        p {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 0px;
            font-size: 16px;
            font-weight: bold;
            width: 100%;
            color: #fff;
            text-align: center;
        }
    }
    .text {
        background: #fff;
        padding: px2rem(40px) px2rem(30px);
        padding-bottom: px2rem(50px);
        .p {
            color: #939393;
            font-size: 14px;
            line-height: 24px;
        }
        .btn {
            margin-top: px2rem(30px);
            color: #939393;
            font-size: 14px;
            a {
                position: relative;
                padding: 0 px2rem(20px);
                font-size: 14px;
                color: #939393;
                &:before {
                    padding-right: 4px;
                }
                + a {
                    &:after {
                        content: "";
                        width: 1px;
                        height: 10px;
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        left: 0px;
                        background: #999;
                    }
                }
            }
        }
    }
}
.strength {
    font-size: 14px;
    color: #666;
    line-height: 24px;
    img {
        margin: px2rem(20px) 0px;
        max-width: 100%;
        height: auto !important;
    }
    .li {
        padding-bottom: 4px;
        padding-left: 16px;
        background: url(../images/brand_icon_1.jpg) no-repeat top left;
        background-position: 0px 12px;
    }
    .li2 {
        display: none;
    }
    .title2 {
        margin: px2rem(20px) 0px;
        color: #333;
        font-size: 16px;
    }
    .title {
        padding-bottom: px2rem(20px);
        font-size: 16px;
        .left {
            margin-right: px2rem(10px);
            font-weight: bold;
        }
        .right {
            padding-left: px2rem(20px);
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translate(0, -40%);
                content: "";
                width: 2px;
                height: 14px;
                background: $red;
            }
        }
    }
    .strength-box {
        color: #777;
        padding: px2rem(40px) px2rem(20px);
        margin: 0 px2rem(-20px);
        margin-top: px2rem(40px);
        background: #fff none no-repeat center center;
        background-size: cover;
        background-image: url(../images/bg.jpg);
        p + p {
            margin-top: px2rem(20px);
        }
        .strength-nav {
            text-align: center;
            div {
                padding: 0 8px;
                display: inline-block;
                font-size: 12px;
                color: #333;
                &.on {
                    color: $red;
                }
                + div {
                    position: relative;
                    i {
                        color: $red;
                        font-size: 16px;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0px;
                        transform: translate(0, -50%);
                        width: 1px;
                        height: 14px;
                        background: #d8d8d8;
                    }
                }
            }
        }
        .strength-li {
            margin-top: px2rem(30px);
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            padding: px2rem(40px) px2rem(30px);
            font-size: 14px;
            color: #888;
            background: #fff;
            display: none;
            &.on {
                display: block;
            }
        }
    }
}
.school {
    &-bg {
        padding: 0 px2rem(30px);
        margin: 0 px2rem(-20px);
        padding-bottom: px2rem(100px);
        background: #fff none no-repeat center center;
        background-size: cover;
        background-image: url(../images/bg2.jpg);
    }
    &-bg2 {
        padding: 0 px2rem(30px);
        margin: 0 px2rem(-20px);
        padding-top: px2rem(50px);
        padding-bottom: px2rem(100px);
        background: #fff none no-repeat center center;
        background-size: cover;
        background-image: url(../images/bg3.jpg);
    }
    &-bg3 {
        padding: 0 px2rem(30px);
        margin: 0 px2rem(-20px);
        padding-top: px2rem(50px);
        padding-bottom: px2rem(100px);
        background: #fff none no-repeat center center;
        background-size: cover;
        background-image: url(../images/bg4.jpg);
        color: #777;
        img {
            width: 120px;
            padding-top: px2rem(50px);
            padding-left: px2rem(20px);
            float: right;
        }
    }
    &-bg4 {
        padding: 0 px2rem(30px);
        margin: 0 px2rem(-20px);
        padding-top: px2rem(50px);
        padding-bottom: px2rem(100px);
        background: #fff none no-repeat center center;
        background-size: cover;
        background-image: url(../images/bg5.jpg);
    }
    font-size: 14px;
    color: #666;
    line-height: 24px;
    .school-box {
        padding-top: px2rem(30px);
        img {
            margin-top: px2rem(30px);
        }
    }
    .li2 {
        padding: px2rem(20px) 0px;
        h5 {
            font-size: 14px;
            color: #333;
            margin-bottom: 4px;
        }
        p {
            line-height: 20px;
            font-size: 14px;
            color: #777;
        }
        + .li2 {
            border-top: 1px dashed #777;
        }
    }
    .title {
        font-size: 16px;
        color: #333;
        position: relative;
        margin-bottom: 14px;
        padding-bottom: 8px;
        font-weight: bold;
        &:after {
            position: absolute;
            content: "";
            left: 0px;
            bottom: 0px;
            width: 20px;
            height: 1px;
            background: $red;
        }
    }
    p {
        margin-bottom: 6px;
        span {
            font-weight: bold;
            color: #444;
        }
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
}
.contact-us-box {
    padding: px2rem(30px) px2rem(40px);
    background: #fff;
    .iconfont {
        color: #a2a2a2;
        font-size: 30px;
        float: left;
        padding-right: 14px;
    }
    .text {
        color: #666;
        font-size: 12px;
        line-height: 24px;
        overflow: hidden;
        .title {
            margin: 5px 0px;
            font-size: 20px;
        }
    }
    + .contact-us-box {
        margin-top: px2rem(20px);
    }
}
.contact-us-title {
    padding-top: px2rem(30px);
    font-size: 20px;
    margin-bottom: px2rem(30px);
    color: #555;
    i {
        font-size: 26px;
        margin-right: px2rem(20px);
    }
    i, span {
        vertical-align: middle;
    }
}
.contact-us-ver-box {
    display: flex;
    margin-bottom: px2rem(20px);
    .box {
        flex: 1;
        background: #fff;
        height: 46px;
        line-height: 46px;
        display: flex;
    }
    .text {
        font-size: 14px;
        color: #666;
        padding-left: px2rem(30px);
    }
    .input {
        flex: 1;
        input {
            display: block;
            width: 100%;
            height: 46px;
            line-height: 46px;
            font-size: 14px;
            color: #666;
            background: none;
            border: none;
            appearance: none;
        }
    }
    .ver-img {
        display: flex;
        height: 46px;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        width: px2rem(200px);
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
.contact-us-input {
    margin-bottom: px2rem(20px);
    background: #fff;
    padding: 0 px2rem(20px);
    .input {
        position: relative;
        height: 46px;
        //padding-left: 7em;
        box-sizing: border-box;
        display: flex;
        .text {
            line-height: 46px;
            color: #666;
            font-size: 14px;
            margin-right: 5px;
            //position: absolute;
            //top: 0px;
            //left: 0px;
        }
        &.textarea {
            box-sizing: border-box;
            //padding-top: 16px;
            //padding-bottom: 16px;
            height: 100px;
        }
        textarea {
            background: #fff;
            border: 0;
            flex: 1;
            resize: none;
            width: 100%;
            height: 100%;
            padding-top: 15px;
            padding-bottom: 15px;
            box-sizing: border-box;
        }
        input,
        select {
            background: #fff;
            border: 0;
            width: 100%;
            height: 100%;
            flex: 1;
        }
    }
}
.contact-us-button {
    button {
        background: $red;
        border: 0;
        line-height: 30px;
        padding: 0 18px;
        font-size: 14px;
        color: #fff;
        min-width: 120px;
        box-sizing: border-box;
    }
}
.recommend-con {
    padding: 20px px2rem(20px) 40px;
    background-color: #f0f0f0;
}
.recommend-list {
    li {
        margin-bottom: 10px;
        a {
            display: block;
            box-sizing: border-box;
            background-color: #fff;
            padding: px2rem(30px);
        }
        h2 {
            font-size: 14px;
            color: #666;
            margin-bottom: px2rem(10px);
        }
        .p {
            font-size: 12px;
            color: #777;
            margin-bottom: px2rem(10px);
        }
        .time {
            font-size: 10px;
            color: #777;
        }
    }
}
.product-main-menu {
    position: relative;
    z-index: 120;
    background-color: #fff;
    &-box {
        //max-width: 750px;
        margin: 0 auto;
        display: flex;
        position: relative;
        z-index: 5;
        background-color: #fff;
    }
    &-btn {
        width: px2rem(90px);
        //height: px2rem(78px);
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            width: px2rem(40px);
            height: px2rem(40px);
            border: 1px solid $red;
            line-height: px2rem(40px);
            text-align: center;
            border-radius: 100%;
            font-size: px2rem(24px);
            color: $red;
            &:before {
                content: '\e673';
            }
        }
        &.show {
            i {
                &:before {
                    content: '\e671';
                }
            }
        }
    }
    &-list {
        flex: 1;
        overflow: hidden;
        font-size: 0;
        display: flex;
        li {
            display: inline-block;
            vertical-align: top;
            width: 25%;
            position: relative;
            box-sizing: border-box;
            padding: 0 px2rem(10px);
            a {
                display: block;
                width: 100%;
                font-size: px2rem(24px);
                color: #666;
                text-align: center;
                line-height: px2rem(28px);
                padding: px2rem(20px) 0;
            }
            img {
                margin: 0 auto px2rem(10px);
                height: px2rem(40px);
            }
            .img {
                display: block;
            }
            .img-hover {
                display: none;
            }
            &.on {
                background-color: $red;
                a {
                    color: #fff;
                }
                .img {
                    display: none;
                }
                .img-hover {
                    display: block;
                }
            }
        }
    }
    &-toggle {
        position: absolute;
        left: 0;
        width: 100%;
        top: px2rem(78px);
        background-color: #fff;
        display: none;
        .bg {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .05);
        }
        &-list {
            font-size: 0;
            padding-right: px2rem(90px);
            padding-top: px2rem(20px);
            padding-bottom: px2rem(20px);
            position: relative;
            z-index: 2;
            background-color: #fff;
            li {
                display: inline-block;
                vertical-align: top;
                width: 25%;
                position: relative;
                box-sizing: border-box;
                padding: 0 px2rem(10px);
                a {
                    display: block;
                    width: 100%;
                    font-size: px2rem(24px);
                    color: #666;
                    text-align: center;
                    line-height: px2rem(28px);
                    padding: px2rem(20px) 0;
                }
                img {
                    margin: 0 auto px2rem(10px);
                    height: px2rem(40px);
                }
                .img {
                    display: block;
                }
                .img-hover {
                    display: none;
                }
                &.on {
                    background-color: #488195;
                }
            }
        }
    }
}
.product-main {
    &-con {
        background-color: #f0f0f0;
        padding: px2rem(50px) px2rem(20px);
    }
    &-title {
        margin-bottom: 14px;
        text-align: center;
        font-size: 20px;
        color: #333;
        span,
        h2 {
            display: inline-block;
            background: url(../images/index_icon_1.png) no-repeat;
            background-size: 40px;
            padding-bottom: 14px;
            padding-left: 34px;
            font-size: 20px;
        }
    }
    &-swiper {
        overflow: hidden;
        .business-pagination {
            padding: 4px 0px 16px;
            text-align: center;
            span {
                background: #333;
                &.swiper-pagination-bullet-active {
                    background: $red;
                }
            }
        }
    }
    &-list {
        font-size: 0;
        .li {
            margin-bottom: px2rem(20px);
            a {
                display: flex;
                background-color: #fff;
            }
            .img {
                flex: 1;
                overflow: hidden;
                span {
                    display: block;
                    width: 100%;
                    padding-top: (246/356) * 100%;
                    position: relative;
                    overflow: hidden;
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                    }
                }
            }
            .info {
                flex: 1;
                overflow: hidden;
                display: flex;
                align-items: center;
            }
            .wrapper {
                box-sizing: border-box;
                padding: px2rem(20px) px2rem(40px);
                overflow: hidden;
                width: 100%;
            }
            .title {
                font-size: px2rem(28px);
                font-weight: bold;
                color: #333;
                line-height: px2rem(40px);
                margin-bottom: 6px;
                max-height: px2rem(40px);
                overflow: hidden;
            }
            .p {
                font-size: px2rem(28px);
                color: #666;
                line-height: px2rem(32px);
                max-height: px2rem(64px);
                overflow: hidden;
                width: 100%;
            }
            .arrow {
                width: px2rem(28px);
                height: px2rem(28px);
                text-align: center;
                line-height: px2rem(28px);
                background-color: $red;
                color: #fff;
                font-size: px2rem(20px);
                border-radius: 100%;
                margin-top: 8px;
            }
            &:nth-child(2n+1) {
                a {
                    flex-direction: row-reverse;
                }
            }
            &:nth-child(2n) {
                .arrow {
                    float: right;
                }
            }
        }
    }
}
.knowledge-classify {
    text-align: center;
    font-size: 14px;
    color: #333;
    span {
        display: inline-block;
        vertical-align: top;
    }
    a {
        display: inline-block;
        vertical-align: top;
        color: #333;
        margin: 0 15px;
        &.on {
            color: $red;
        }
    }
}
.knowledge-side {
    padding: px2rem(40px) px2rem(20px);
    background-color: #f5f5f5;
    &-con {
        margin-bottom: px2rem(50px);
    }
    &-title {
        text-align: center;
        font-size: 16px;
        color: #333;
        margin-bottom: 10px;
        img {
            display: inline-block;
            height: 16px;
            vertical-align: middle;
            margin-right: 5px;
        }
    }
    &-list {
        li {
            background-color: #fff;
            border-top: 1px dashed #d5d5d5;
            &:nth-child(n+4) {
                display: none;
            }
            a {
                display: block;
                box-sizing: border-box;
                padding: 0 px2rem(30px);
                height: 45px;
                line-height: 45px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                color: #777;
                &:before {
                    display: inline-block;
                    vertical-align: middle;
                    width: 6px;
                    height: 6px;
                    border: 1px solid $red;
                    border-radius: 100%;
                    content: '';
                    margin-right: 5px;
                }
            }
        }
        &.open {
            li:nth-child(n+4) {
                display: block;
            }
        }
    }
    &-more {
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-top: 1px dashed #d5d5d5;
        img {
            transition: all .2s;
            height: 14px;
        }
        &.open {
            img {
                transform: rotate(180deg);
            }
        }
    }
}
.knowledge-detail {
    padding: px2rem(50px) px2rem(20px) 0;
    background-color: #f0f0f0;
    overflow: hidden;
    &-top {
        text-align: center;
        padding-bottom: px2rem(40px);
        .title {
            font-size: 16px;
            font-weight: bold;
            color: #333;
            margin-bottom: 5px;
        }
        .tip {
            font-size: 11px;
            color: #777;
        }
    }
    &-article {
        border-top: 1px dashed #d5d5d5;
        padding-top: 15px;
        margin-bottom: px2rem(40px);
        .article {
            color: #777;
            line-height: 24px;
            overflow: hidden;
            height: 380px;
            @include articleReset(14px, 24px);
            &.open {
                height: auto;
            }
        }
        .more {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: $red;
            height: 45px;
            cursor: pointer;
        }
    }
}
.about-con {
    background: #f4f4f4;
    padding: px2rem(50px) px2rem(20px);
    font-size: px2rem(24px);
    line-height: px2rem(48px);
    color: #666;
    img {
        max-width: 100%;
        height: auto;
    }
    p {
        margin-bottom: px2rem(24px);
    }
}
.about-white-box {
    background-color: #fff;
    padding: px2rem(45px) px2rem(40px);
    .title {
        font-size: px2rem(34px);
        color: #333;
        padding-bottom: px2rem(20px);
        position: relative;
        margin-bottom: px2rem(20px);
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: px2rem(27px);
            height: px2rem(3px);
            background: $red;
        }
    }
}
.about-list {
    font-size: 0;
    text-align: center;
    .item {
        box-sizing: border-box;
        width: 50%;
        display: inline-flex;
        vertical-align: top;
        color: #346ef1;
        font-weight: bold;
        font-size: px2rem(20px);
        align-items: center;
        margin-bottom: px2rem(50px);
        text-align: left;
        strong {
            font-family: Arial;
            font-size: px2rem(60px);
        }
    }
}
.about-article-box {
    padding: px2rem(40px);
}