.index-banner {
    width: 100%;
    padding-bottom: (820/750)*100%;
    position: relative;
    .banner-bg {
        position: absolute;
        display: block;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 2;
    }
    .banner-box {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        a {
            display: block;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .index-pagination {
        position: absolute;
        top: auto;
        bottom: px2rem(40px);
        left: 0px;
        text-align: center;
        width: 100%;
        z-index: 10;
        span {
            opacity: 1;
            background: #fff;
            width: 10px;
            height: 10px;
            &.swiper-pagination-bullet-active {
                background: $red;
            }
        }
    }
}
.index-title {
    position: relative;
    text-align: center;
    font-size: px2rem(48px);
    padding-bottom: px2rem(20px);
    &:before {
        position: absolute;
        left: 50%;
        bottom: 0;
        content: '';
        width: px2rem(50px);
        height: px2rem(3px);
        margin-left: px2rem(-25px);
        background: $red;
    }
}
.index-business {
    background-color: #fff;
    position: relative;
    overflow: hidden;
    padding: px2rem(60px) px2rem(50px) px2rem(120px);
}
.index-business-topic {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-top: px2rem(30px);
    padding: 0 px2rem(40px);
    margin-bottom: px2rem(30px);
    .top-word {
        padding: px2rem(60px) 0;
        color: #fff;
        .title {
            position: relative;
            font-size: px2rem(34px);
            font-weight: bold;
            padding-bottom: px2rem(15px);
            margin-bottom: px2rem(10px);
            &:before {
                position: absolute;
                left: 0;
                bottom: 0;
                content: '';
                background: #fff;
                width: px2rem(25px);
                height: px2rem(2px);
            }
        }
        .p {
            font-size: px2rem(24px);
            line-height: px2rem(34px);
        }
    }
    .top-swiper {
        background: rgba(#f5f5f5, .7);
        .swiper-slide {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: px2rem(190px);
            .icon {
                border-radius: 100%;
                border: 1px solid #cecece;
                background: #fff;
                width: px2rem(78px);
                height: px2rem(78px);
                margin-bottom: px2rem(15px);
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                img {
                    width: px2rem(40px);
                    height: px2rem(40px);
                }
                &:before {
                    background: $red;
                    position: absolute;
                    content: '';
                    left: 50%;
                    bottom: px2rem(10px);
                    width: px2rem(12px);
                    height: px2rem(4px);
                    margin-left: px2rem(-6px);
                }
            }
            .p {
                font-size: px2rem(22px);
                color: #333;
            }
        }
    }
}
.index-business-list {
    .item {
        display: block;
        position: relative;
        overflow: hidden;
        margin-bottom: px2rem(20px);
        img {
            display: block;
            width: 100%;
        }
        .title {
            position: absolute;
            color: #fff;
            font-weight: bold;
            left: px2rem(34px);
            top: px2rem(34px);
            z-index: 2;
            padding-left: px2rem(17px);
            font-size: px2rem(30px);
            &:before {
                position: absolute;
                left: 0;
                top: 50%;
                background: #fff;
                content: '';
                width: px2rem(3px);
                height: px2rem(24px);
                margin-top: px2rem(-12px);
            }
        }
    }
}
.index-investor {
    background-position: center top;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    padding-top: px2rem(130px);
    box-sizing: border-box;
    min-height: px2rem(1146px);
    margin-top: px2rem(-84px);
    z-index: 25;
    &-list {
        padding-top: px2rem(50px);
        li {
            text-align: center;
            margin-bottom: px2rem(70px);
        }
        .num-box {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .num {
            font-family: Arial;
            font-weight: bold;
            font-size: px2rem(72px);
            color: $red;
        }
        .unit {
            font-size: 8px;
            color: #333;
            p {
                font-size: px2rem(24px);
            }
        }
        .p {
            font-size: px2rem(28px);
            line-height: px2rem(40px);
            color: #333;
        }
    }
}
.index-report {
    position: relative;
    margin-top: px2rem(-86px);
    overflow: hidden;
    z-index: 24;
    img {
        display: block;
        width: 100%;
    }
    .word {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 2;
        text-align: center;
        .t {
            color: #fff;
            font-size: px2rem(60px);
            font-weight: bold;
            margin-bottom: px2rem(40px);
        }
        .btn {
            display: inline-block;
            vertical-align: top;
            color: #fff;
            font-size: px2rem(24px);
            height: px2rem(62px);
            border-radius: px2rem(31px);
            line-height: px2rem(62px);
            padding: 0 px2rem(50px);
            background: rgba(#01ae42, .5);
        }
    }
}
.index-brand {
    position: relative;
    z-index: 23;
    margin-top: px2rem(-86px);
    overflow: hidden;
    background-image: url(../images/index_img_11.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 100%;
    min-height: px2rem(1182px);
    box-sizing: border-box;
    padding: px2rem(160px) px2rem(50px) px2rem(86px);
    &-title {
        position: relative;
        padding-bottom: px2rem(30px);
        margin-bottom: px2rem(70px);
        color: #fff;
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            background: #fff;
            content: '';
            width: px2rem(140px);
            height: px2rem(3px);
        }
        .s {
            font-size: px2rem(42px);
        }
        .b {
            font-size: px2rem(70px);
        }
    }
    &-video {
        width: 100%;
        position: relative;
        overflow: hidden;
        padding-top: percentage(367/650);
        video {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
        }
        .video-js {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: none;
            .vjs-big-play-button {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 2em;
            }
        }
    }
    &-p {
        padding: px2rem(40px);
        color: #fff;
        text-align: justify;
        font-size: px2rem(26px);
        line-height: px2rem(45px);
    }
}
.index-news {
    position: relative;
    background-color: #f5f5f5;
    padding: px2rem(100px) px2rem(50px);
    margin-top: px2rem(-86px);
    &-topic {
        margin-top: px2rem(30px);
        display: block;
        background-color: #fff;
        .img {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: percentage(449/650);
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
        .info {
            padding: px2rem(25px) px2rem(40px);
        }
        .t {
            font-size: px2rem(32px);
            line-height: px2rem(42px);
            height: px2rem(84px);
            overflow: hidden;
            color: #333;
            margin-bottom: px2rem(15px);
        }
        .time {
            font-size: px2rem(24px);
            color: #ababab;
        }
    }
    &-list {
        li {
            padding: px2rem(30px) 0;
            border-bottom: 1px dashed #cecece;
        }
        .t {
            font-size: px2rem(26px);
            line-height: px2rem(42px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: px2rem(10px);
            position: relative;
            padding-left: px2rem(20px);
            &:before {
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: px2rem(-13px);
                height: px2rem(26px);
                width: px2rem(6px);
                border-radius: px2rem(3px);
                content: '';
                background: $red;
            }
            a {
                color: #333;
            }
        }
        .time {
            font-size: px2rem(24px);
            color: #ababab;
        }
    }
}